.d-flex .card {
  min-width: 15%;
  /*  max-width: 18%;*/
  padding: 0.5rem;
  margin: 0.5rem;
  flex: 1;
}

section {
  margin: 1rem 0;
}

.d-flex {
  width: 100%;
  font-size: 1rem;
}

.d-flex .setup-pages {
  flex: 1;
}

.nav a:hover {
  text-decoration: none;
}

.green-check svg {
  font-size: 1.6rem;
  color: green;
}

.red-x svg {
  font-size: 2rem;
  color: red;
}

.center-label {
  display: flex;
  justify-content: center; /* Horizontally center align content */
  align-items: center; /* Vertically center align content */
}

.center-line {
  display: flex;
  align-items: center; /* Vertically center align content */
  margin: 1rem auto;
}

.center-line .form-group {
  margin: 0;
}

.center-label label {
  margin: 0;
}

@media print {
  .d-flex .card button,
  .d-flex .newStageCard,
  .d-flex .nav-pills,
  .d-flex button {
    display: none;
  }

  .d-flex .card {
    width: 15%;
    margin-right: 2%;
  }

  .d-flex {
    margin: 0 auto;
  }
}

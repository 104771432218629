.scoreboardContainer {
  background-color: var(--background-haze);
  color: var(--text-primary);
}

.animateName {
  animation: marquee 12s ease-in-out infinite;
}

/*@keyframes marquee {
  0%,
  10%,
  90%,
  100% {
    transform: translateX(0%);
  }
  45%,
  55% {
    transform: translateX(-40%);
  }
}*/

.producerMode {
  padding-top: 42.25% !important;
}

.producerMode .scoreboardDisplay .scoreboardTeamSingleLine .teamScore,
.producerMode .scoreboardDisplay .scoreboardTeamSingleLine .teamScore span,
.producerMode .scoreboardDisplay .scoreboardTeamSingleLine .teamName {
  font-size: 1.5vw !important;
  padding: 0;
  padding-left: 0.5vw !important;
  padding-right: 0.5vw !important;
  text-wrap: wrap;
  line-height: 1.5vw;
}

.producerMode .scoreboardDisplay .scoreboardTeamSingleLine .rank {
  font-size: 0.75vw !important;
  padding-left: 0.35vw !important;
  padding-right: 0.35vw !important;
}

.xs .scoreboardDisplay .topHalf .msg .score {
  font-size: 24vw;
}

.sm .scoreboardDisplay .topHalf .msg .score {
  font-size: 18vw;
}

.xs .scoreboardDisplay .topHalf .athleteImgSM {
  display: none;
}

.xs .scoreboardDisplay .topHalf .scoreboardHeader .teamInfo img {
  opacity: 25%;
}

.xs .scoreboardDisplay .scoreboardBody .indicators,
.sm .scoreboardDisplay .scoreboardBody .indicators {
  align-self: flex-end;
}

.sm .scoreboardDisplay .topHalf,
.xs .scoreboardDisplay .topHalf {
  height: 75%;
  font-family: sans-serif;
  direction: rtl;
}

.sm .scoreboardDisplay .topHalf .name,
.xs .scoreboardDisplay .topHalf .name {
  display: block;
  left: 1rem;
  direction: ltr;
  line-height: 1;
  font-size: 12vw;
}

.sm .scoreboardDisplay .topHalf .name .col,
.xs .scoreboardDisplay .topHalf .name .col {
  overflow: visible;
  white-space: nowrap;
  text-overflow: initial;
}

.sm .scoreboardDisplay .bottomHalf,
.xs .scoreboardDisplay .bottomHalf {
  height: 25%;
  font-family: sans-serif;
  direction: ltr;
}

.sm .scoreboardDisplay .subScores .subScoresRow .jScore,
.xs .scoreboardDisplay .subScores .subScoresRow .jScore {
  display: flex;
  font-size: 12vw;
}

.sm .scoreboardDisplay .mainScore .score .indicators,
.xs .scoreboardDisplay .mainScore .score .indicators {
  display: block;
}

.sm .scoreboardDisplay .mainScore .score,
.xs .scoreboardDisplay .mainScore .score {
  font-size: 24vw;
  margin-top: 0;
  line-height: initial;
  /*  line-height: 1;*/
  font-variant-numeric: tabular-nums;
  text-align: left;
  /*  height: 75%;*/
  direction: ltr;
  display: flex;
  align-items: center;
}

.sm .scoreboardDisplay .mainScore .score .is4digs {
  font-size: 22vw;
}

.xs .scoreboardDisplay .mainScore .score .is4digs {
  font-size: 26vw;
}

.xs .scoreboardDisplay .mainScore .score {
  line-height: 1;
  font-size: 30vw;
}

.scoreboardContainer {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.scoreboardDisplay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: none;
}

.showCursor {
  cursor: default !important;
}

.scoreboardDisplay .scoreboardBody {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
}

.scoreboardDisplay .scoreboardTeamSingleLine,
.scoreboardDisplay .scoreboardTeamSingleLine .rank,
.scoreboardDisplay.row,
.scoreboardDisplay .scoreboardHeader,
.scoreboardDisplay .scoreboardFooter,
.scoreboardDisplay .scoreboardBody,
.scoreboardDisplay .scoreboardBody .score,
.scoreboardDisplay .topHalf,
.scoreboardDisplay .topHalf .name,
.scoreboardDisplay .bottomHalf {
  margin-left: 0;
  margin-right: 0;
}

.scoreboardDisplay .scoreboardBody,
.scoreboardDisplay .scoreboardFooter,
.scoreboardDisplay .topHalf,
.scoreboardDisplay .scoreboardHeader,
.scoreboardDisplay .bottomHalf,
.scoreboardDisplay .scoreboardBody .score,
.scoreboardDisplay .scoreboardTeamSingleLine {
  width: 100%;
}

.scoreboardDisplay .topHalf {
  height: 66%;
  position: relative;
}

.scoreboardDisplay .bottomHalf {
  height: 33%;
  position: relative;
}

.scoreboardDisplay .bottomHalf .subScores.onAir,
.scoreboardDisplay .bottomHalf .logoCol.onAir,
.scoreboardDisplay .bottomHalf .logoCol.onAir .apparatusLogo,
.scoreboardDisplay .bottomHalf .logoCol.onAir .apparatusLogo svg {
  background: #28a745 !important;
  color: var(--text-primary);
}

.scoreboardDisplay .bottomHalf .subScores.onEval,
.scoreboardDisplay .bottomHalf .logoCol.onEval,
.scoreboardDisplay .bottomHalf .logoCol.onEval .apparatusLogo,
.scoreboardDisplay .bottomHalf .logoCol.onEval .apparatusLogo svg {
  background: #ffc107 !important;
  color: var(--text-primary);
}

.scoreboardDisplay .topHalf .score {
  text-align: left;
  /*font-family: 'Roboto', sans-serif;*/
  font-size: 18vw;
  width: 100%;
  font-weight: bolder;
  line-height: 22vh;
  position: relative;
  margin-top: 10%;
}

.scoreboardDisplay .topHalf .score span {
  position: absolute;
  bottom: 0;
  line-height: 20rem;
}

.scoreboardDisplay .topHalf .name {
  font-size: 7vw;
  font-weight: bolder;
  color: var(--text-quaternary);
  position: absolute;
  bottom: 0;
  width: 100% !important;
  text-align: left;
}

.scoreboardDisplay .topHalf .name .col {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scoreboardDisplay .topHalf .athleteImg img {
  width: 90%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

.scoreboardDisplay .topHalf .athleteImgSM img {
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}

.scoreboardDisplay .scoreboardHeader {
  height: 50%;
  width: 100%;
}

.scoreboardDisplay .scoreboardBody .mainScore {
  border-top: 1rem double;
}

.scoreboardDisplay.scoreboardHeader .teamInfo {
  position: relative;
}

.scoreboardDisplay .scoreboardHeader .teamInfo img {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  height: 120%;
  padding: 10%;
}

.scoreboardDisplay .scoreboardFooter .apparatusLogo {
  height: 100%;
  width: 100%;
  position: relative;
}

.scoreboardDisplay .scoreboardFooter .apparatusLogo svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 75%;
  width: 75%;
  transform: translate(-50%, -50%);
  color: var(--text-quaternary);
}

.scoreboardDisplay .scoreboardHeader .teamName {
  font-size: 6rem;
  line-height: 6rem;
}

.scoreboardDisplay .scoreboardHeader .col {
  height: 100%;
}

.scoreboardDisplay .scoreboardHeader .teamInfo img,
.scoreboardDisplay .scoreboardHeader .apparatusLogo svg {
  /*filter: drop-shadow(0 0 0.3rem var(--text-primary)); */
}

[data-theme='dark'] .scoreboardDisplay .scoreboardHeader .teamInfo img,
/*[data-theme='dark'] .scoreboardDisplay .scoreboardBody .athleteImg img, */
[data-theme='dark'] .scoreboardDisplay .scoreboardHeader .apparatusLogo svg {
  filter: drop-shadow(0 0 0.3rem var(--text-primary));
}

.scoreboardDisplay .scoreboardBody .indicators {
  font-size: 7.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--background);
}

.scoreboardDisplay .scoreboardBody .indicators svg {
  padding: 0.5rem;
}

.scoreboardDisplay .scoreboardFooter .logoCol {
  background: var(--header-gradient-left);
}

.scoreboardDisplay .scoreboardFooter .subScores {
  background-color: var(--background);
}

.scoreboardDisplay .scoreboardFooter .subScoresRow {
  display: flex;
  /*  justify-content: center;*/
  align-items: center;
  height: 100% !important;
}

.scoreboardDisplay .scoreboardFooter .subScores .apparatus {
  font-size: 7vw;
  color: var(--header-gradient-left);
  font-style: italic;
}

.scoreboardDisplay .scoreboardFooter .subScores .dScore {
  font-size: 8vw;
  font-weight: bolder;
  text-align: center;
}

.scoreboardDisplay .scoreboardFooter .subScores .dScore sub {
  font-size: 5vw;
  color: var(--text-quaternary);
}

.scoreboardDisplay .scoreboardFooter .subScores .eScore {
  font-size: 8vw;
  font-weight: bolder;
  text-align: center;
}

.scoreboardDisplay .scoreboardFooter .subScores .eScore sub {
  font-size: 5vw;
  color: var(--text-quaternary);
}

.scoreboardDisplay .scoreboardFooter .subScores .ndScore,
.scoreboardDisplay .scoreboardFooter .subScores .jScore {
  font-size: 6vw;
  font-weight: bolder;
  text-align: center;
  display: block;
  line-height: 1.2;
  font-variant-numeric: tabular-nums;
}

.scoreboardDisplay .scoreboardFooter .subScores .ndScore sub,
.scoreboardDisplay .scoreboardFooter .subScores .jScore sub {
  font-size: 4vw;
  color: var(--text-quaternary);
  margin: 0 0.5rem;
}

.scoreboardDisplay .matchCountdown {
  font-size: 3vw;
}

.scoreboardDisplay .matchCountdown .zoom {
  margin-top: -3vw;
}

.scoreboardDisplay .matchCountdown .heroMessage p,
.scoreboardDisplay .matchCountdown .countdownTimer {
  font-size: 3vw;
}

.scoreboardDisplay .heroMessage .calendarAdd,
.scoreboardDisplay .heroMessage .matchTitle {
  display: none;
}

.scoreboardDisplay .heroMessage .matchLogos img {
  height: 20vh;
}

.scoreboardDisplay .heroMessage .matchLogos button span {
  font-size: 3vw;
  font-weight: bold;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamLogo {
  height: 100%;
  padding: 0;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamLogo .logoRow {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamLogo img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  object-fit: contain;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .rank {
  text-align: center;
  width: 100%;
  font-size: 4vh;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff;
}

.scoreboardDisplay .scoreboardTeamSingleLine .rank .rankText {
  width: 100%;
}

.scoreboardDisplay .scoreboardTeamSingleLine .rank span {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .rank sub {
  font-size: 50%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamName {
  font-size: 7.5vw;
  display: flex;
  align-items: center;
  color: #fff;
  padding-left: 2vw;
  font-weight: bold;
  white-space: nowrap;
  z-index: 10;
  height: 100%;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamName span {
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamName svg {
  width: 100%;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamScore {
  font-size: 7.5vw;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  height: 100%;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamScore span {
  padding-right: 2vw;
  font-variant-numeric: tabular-nums;
  text-align: right;
  width: 100%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamApparatusScore,
.scoreboardDisplay .scoreboardTeamSingleLine .teamApparatusTotalScore {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
  height: 100%;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamApparatusScore span {
  font-size: 3.6vw;
  padding-right: 0vw;
  font-variant-numeric: tabular-nums;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamApparatusTotalScore span {
  font-size: 4.4vw;
  padding-right: 0vw;
  font-variant-numeric: tabular-nums;
  text-align: center;
  width: 100%;
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #111);
}

.scoreboardDisplay .scoreboardTeamSingleLine .is4digs span {
  font-size: 3.6vw;
}

.scoreboardDisplay .scoreboardTeamSingleLine .isSmallFont span {
  font-size: 2.25vw;
}

.scoreboardDisplay .scoreboardTeamSingleLine .teamApparatusScore svg {
  height: 66%;
  width: 100%;
}

.main {
  width: 100%;
  height: 100%;
  color: var(--text-primary); /*#222*/
  font-size: 2rem;
  position: relative;
  background-color: var(--background);
}

.lineups {
  background-color: var(--background);
  margin-top: 0.5rem;
  margin-bottom: 80px;
}

.players {
  padding-top: 12px;
  padding-bottom: 12px;
  position: sticky;
  position: -webkit-sticky;
  top: 72px;
  z-index: 10;
  background-color: var(--background);
}

.calendar-column {
  text-align: center;
}

.fullscreen {
  position: fixed;
  width: 100% !important;
  height: 100vh;
  max-width: 100% !important;
}

.fullscreen .lineups {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.fullscreen .lineups .left,
.fullscreen .lineups .right {
  max-width: 33%;
  bottom: 0;
  position: absolute;
}

.fullscreen .lineups .left {
  left: 0;
}

.fullscreen .lineups .right {
  right: 0;
}

.fullscreen .lineups .center {
  display: none;
}

.fullscreen .competitorList li,
.fullscreen .scoreDeltas li {
  /*margin-bottom: 0;*/
  height: 5vh;
}

.fullscreen .competitorList .headshot img {
  max-height: 5vh;
  /*margin-top: 0.5vh*/
}

.fullscreen .lineups hr {
  /*margin: 0;*/
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.fullscreen .competitorList .score {
  border-radius: 0;
}

.fullscreen .roundSummary,
.fullscreen .rDeltaRow {
  height: 6vh;
}

.fullscreen .competitorLine {
  height: 5vh;
}

.mobile .teamBanner,
.mobile .roundSummary {
  font-size: 1.2rem;
}

.mobile .scoreDeltas li,
.mobile .scoreDeltas .rDeltaCol,
.mobile .dScoreIndicator,
.mobile .inquiryIndicator,
.mobile .penaltyIndicator,
.mobile .stickIndicator,
.mobile .competitorList .athlete input {
  font-size: 0.6rem;
}

.mobile .competitorList .athlete input {
  padding: 0;
}

.mobile .competitorList .scorecard {
  font-size: 0.8rem;
}

.mobile .roundSummary .score {
  max-width: 100%;
  flex-grow: 1;
}

.mobile .teamBanner .team,
.mobile .roundSummary .team,
.mobile .roundSummary .logo {
  display: none;
}

.mobile .competitorLine .logo {
  position: absolute;
  max-width: fit-content;
  background: none !important;
  z-index: 2;
  bottom: 0;
}

.mobile .competitorLine .logo .buttonContainer,
.mobile .competitorLine .logo .buttonContainer button {
  width: 24px;
  height: 24px;
}

.mobile .right .competitorLine .logo {
  right: 56px;
}

.mobile .left .competitorLine .logo {
  left: 56px;
}

.mobile .competitorList .buttonContainer button .athletePlay {
  font-size: inherit;
}

.mobile .container,
.mobile .header .headers .center {
  max-width: 100%;
}

.mobile .col-2 {
  max-width: 100%;
}

.mobile .logo img {
  max-width: none;
}

.mobile .sDeltaCol {
  padding: 0 6px;
}

.mobile .deltaBarLeft,
.mobile .deltaBarRight {
  height: 4px;
}

.mobile .competitorList .athleteName {
  display: block;
  font-size: 0.6rem;
  position: absolute;
  top: 2px;
}

.mobile .competitorList .athleteName p {
  margin-top: 0;
  margin-bottom: 0;
}

.mobile .left .competitorList .athleteName {
  text-align: left;
  left: 0;
}

.mobile .right .competitorList .athleteName {
  text-align: right;
  right: 0;
}

.mobile .competitorList .athlete {
  max-width: 33.666667%;
}

.mobile .competitorList .athlete input {
  display: none;
}

.mobile .competitorList .score {
  max-width: fit-content;
}

.mobile .competitorList .inquiryIndicator {
  top: 10px;
}

.mobile .competitorList .stickIndicator,
.mobile .competitorList .inquiryIndicator,
.mobile .competitorList .penaltyIndicator {
  width: 0.6rem;
  height: 0.6rem;
}

@media (max-width: 576px) {
  .teamBanner,
  .right .roundSummary,
  .left .roundSummary {
    font-size: 1.2rem;
  }

  .roundcount button {
    font-size: 1.2rem;
  }

  .scoreDeltas li,
  .scoreDeltas .rDeltaCol,
  .dScoreIndicator,
  .inquiryIndicator,
  .penaltyIndicator,
  .stickIndicator,
  .competitorList .athlete input {
    font-size: 0.6rem;
  }

  .competitorList .athlete input {
    padding: 0;
  }

  .competitorList .scorecard {
    font-size: 0.8rem;
  }

  .roundSummary .score {
    max-width: 100%;
    flex-grow: 1;
  }

  .sessionFooter .footers,
  .sessionFooter .footerToggle,
  .session .userBubble,
  .session .menuBubble,
  .teamBanner .team,
  .roundSummary .team,
  .roundSummary .logo {
    display: none !important;
  }

  .vs .competitorLine .logo {
    position: absolute;
    max-width: fit-content !important;
    background: none !important;
    z-index: 2;
    bottom: 0;
    padding: 0 !important;
  }

  .soloLineup .competitorList .competitorLine .logo {
    left: auto;
    position: relative;
    max-width: initial !important;
    padding: initial;
    background: linear-gradient(var(--background-haze) 3% 100%) !important;
  }

  .soloLineup .competitorList .competitorLine .athlete .athleteName {
    display: none !important;
  }

  .soloLineup .competitorList .athlete {
    max-width: 100%;
  }

  .soloLineup .competitorList .athlete input {
    display: block;
  }

  .soloLineup .competitorList .score {
    max-width: initial;
  }

  .competitorLine .logo .buttonContainer,
  .competitorLine .logo .buttonContainer button {
    width: 24px;
    height: 24px;
  }

  .right .competitorLine .logo {
    right: 56px;
  }

  .left .competitorLine .logo {
    left: 56px;
  }

  .competitorList .buttonContainer button {
    cursor: not-allowed;
    /*pointer-events: none;*/
  }

  .competitorList .buttonContainer button .athletePlay {
    font-size: inherit;
  }

  .container,
  .header .headers .center {
    max-width: 100% !important;
  }

  .col-2 {
    max-width: 100% !important;
  }

  .logo img {
    max-width: none !important;
  }

  .sDeltaCol {
    padding: 0 6px !important;
  }

  .deltaBarLeft,
  .deltaBarRight {
    height: 4px;
  }

  .competitorList .pending {
    font-size: 0.6rem;
  }

  .competitorList .athleteName {
    display: block !important;
    font-size: 0.6rem;
    position: absolute;
    top: 2px;
  }

  .competitorList .athleteName p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .left .competitorList .athleteName {
    text-align: left;
    left: 0;
  }

  .right .competitorList .athleteName {
    text-align: right;
    right: 0;
  }

  .competitorList .athlete {
    max-width: 33.666667%;
    z-index: 1;
  }

  .competitorList .athlete input {
    display: none;
  }

  .competitorList .headshot {
    min-width: 56px;
  }

  .competitorList .score {
    max-width: fit-content;
  }

  .competitorList .inquiryIndicator {
    top: 10px;
  }

  .competitorList .stickIndicator,
  .competitorList .inquiryIndicator,
  .competitorList .penaltyIndicator {
    width: 0.6rem;
    height: 0.6rem;
  }

  .session .userBubble,
  .session .menuBubble {
    max-width: 2.5%;
  }
}

@media (max-width: 375px) {
  .userBubble-menu {
    transform: translate3d(-48px, -1px, 0px) !important;
  }
}

@media (max-width: 576px) {
  .userBubble-menu {
    transform: translate3d(-48px, -1px, 0px) !important;
  }
}

@media (min-width: 576px) {
  html {
    font-size: 0.7rem;
  }
  .competitorList .headshot img {
    min-width: auto;
  }
  .userBubble-menu {
    transform: translate3d(-72px, -1px, 0px) !important;
  }
  .session .menuBubble-menu {
    transform: translate3d(24px, -1px, 0px) !important;
  }
  .evaluator .evalPanel,
  .evaluator .evalExtras {
    padding-left: 0;
    padding-right: 0;
  }
  .evaluator button {
    min-width: auto;
  }
  .session .userBubble,
  .session .menuBubble {
    max-width: 2.5%;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 0.8rem;
  }
  .userBubble-menu {
    transform: translate3d(-48px, -1px, 0px) !important;
  }
  .session .menuBubble-menu {
    transform: translate3d(32px, -1px, 0px) !important;
  }
  .evaluator .evalPanel,
  .evaluator .evalExtras {
    padding-left: 0;
    padding-right: 0;
  }
  .evaluator button {
    min-width: auto;
  }
  .session .userBubble,
  .session .menuBubble {
    max-width: 2.5%;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 0.9rem;
  }
  .userBubble-menu {
    transform: translate3d(-48px, -1px, 0px) !important;
  }
  .session .userBubble-menu {
    transform: translate3d(-32px, -1px, 0px) !important;
  }
  .evaluator .evalPanel,
  .evaluator .evalExtras {
    padding-left: 0;
    padding-right: 0;
  }
  .session .userBubble,
  .session .menuBubble {
    max-width: 2.5%;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 1rem;
  }
  .userBubble-menu,
  .session .userBubble-menu {
    transform: translate3d(-48px, -1px, 0px) !important;
  }
  .session .menuBubble-menu {
    transform: translate3d(48px, -1px, 0px) !important;
  }
  .session .userBubble,
  .session .menuBubble {
    max-width: 5%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

@media (max-height: 500px) and (orientation: landscape) {
  .main .header,
  .main .players {
    position: inherit;
  }
}

@media (max-width: 374px) {
  .teamBanner,
  .right .roundSummary,
  .left .roundSummary {
    font-size: 1rem;
  }
  .competitorList .athlete {
    max-width: 20%;
  }
  .left .competitorList .athleteName p {
    display: inline;
    white-space: nowrap;
    margin-right: 0.2rem;
  }
  .right .competitorList .athleteName p {
    display: inline;
    white-space: nowrap;
    margin-left: 0.2rem;
  }
}

/* Added for Single Video View Prototype */
.soloPlayer,
.dualPlayer {
  min-width: 100% !important;
}

.soloPlayer .players,
.dualPlayer .players {
  padding-top: 0px;
  border-bottom: 1px solid #eee;
  position: initial;
}

.soloPlayer .players .row,
.dualPlayer .players .row {
  position: relative;
}

.soloPlayer .triggerPanelRow .row,
.dualPlayer .triggerPanelRow .row {
  margin: 0;
}

.soloPlayer .videoWrapper {
  z-index: 1000;
  height: calc((9 / 16) * 100vw);
  max-height: calc(66vh);
  /*min-height: 480px;*/
  padding-top: inherit;
}

.soloPlayer .videoWrapper .videoPlayer {
}

.dualPlayerWrapper {
  background: black;
}

.dualPlayerWrapper .left,
.dualPlayerWrapper .right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dualPlayer .videoWrapper {
  z-index: 1000;
}

.soloPlayer .videoWrapper,
.soloPlayer .videoWrapper .videoPlayer,
.soloPlayer .videoWrapper video,
.soloPlayer .videoWrapper .playerModal {
  border-radius: 0;
}

.soloPlayer .videoWrapper video {
  background: black;
  height: 1000px;
}

.soloPlayer .triggerPanelRow,
.soloPlayer .lineups {
}

@media (min-width: 992px) {
  .soloPlayer .lineups,
  .soloPlayer .triggerPanelRow,
  .dualPlayer .lineups,
  .dualPlayer .triggerPanelRow {
    max-width: 1140px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .soloPlayer .lineups,
  .soloPlayer .triggerPanelRow,
  .dualPlayer .lineups,
  .dualPlayer .triggerPanelRow {
    max-width: 1140px;
    max-width: 90%;
  }
}

@media (min-width: 1600px) {
  .soloPlayer .lineups,
  .soloPlayer .triggerPanelRow,
  .dualPlayer .lineups,
  .dualPlayer .triggerPanelRow {
    max-width: 1140px;
    max-width: 90%;
  }
}

@media (min-width: 1920px) {
  .soloPlayer .lineups,
  .soloPlayer .triggerPanelRow,
  .dualPlayer .lineups,
  .dualPlayer .triggerPanelRow {
    max-width: 1600px;
  }
}

@media (min-width: 2560px) {
  .soloPlayer .lineups,
  .soloPlayer .triggerPanelRow,
  .dualPlayer .lineups,
  .dualPlayer .triggerPanelRow {
    max-width: 1920px;
  }
}

.scoreDeltas {
  height: 100%;
  font-size: 1.2rem;
  color: var(--text-tertiary);
  text-align: center;
}

[font-theme='800'] .scoreDeltas,
[font-theme='700'] .scoreDeltas,
[font-theme='600'] .scoreDeltas,
[font-theme='800'] .scoreDeltas .rDeltaCol,
[font-theme='700'] .scoreDeltas .rDeltaCol,
[font-theme='600'] .scoreDeltas .rDeltaCol {
  font-weight: var(--font-weight);
  font-size: 1.8vw;
}

.competitorList {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.2rem;
  line-height: 1;
  white-space: nowrap;
  width: 100%;
}

.popover-body .statusSelector {
  height: 2rem;
  width: 2rem;
  margin: 0 0.1rem;
  border-radius: 1rem;
}

.left .competitorList .logo {
  /*border-radius: 4px 0 0 4px;*/
}

.right .competitorList .logo {
  /*border-radius: 0 4px 4px 0;*/
}

.left .competitorList .score {
  border-radius: 0 4px 4px 0;
}

.right .competitorList .score {
  border-radius: 4px 0 0 4px;
}

.left .competitorList .first .logo {
  border-radius: 12px 0 0 4px;
}

.right .competitorList .first .logo {
  border-radius: 0 12px 4px 0;
}

.competitorList .last .logo {
  border-radius: 4px 0 0 12px;
}

.right .competitorList .last .logo {
  border-radius: 0 4px 12px 0;
}

.left .competitorList .first .score {
  border-radius: 0 12px 4px 0;
}

.left .competitorList .last .score {
  border-radius: 0 4px 12px 0;
}

.competitorList .headshot img {
  object-fit: contain;
  max-height: 6.5vh;
  margin-top: -0.5vh;
  min-height: 56px;
  min-width: 48px;
}

[data-theme='dark'] .competitorList .headshot img {
  filter: drop-shadow(0 -1px 0.1rem #888);
}

[data-theme='dark'] .competitorList .headshot img {
  filter: drop-shadow(0 -1px 0.1rem #888);
}

.competitorList .score .clipPlay {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 0;
}

.competitorList .headshot,
.competitorList .athlete {
  background-color: var(--background-haze);
  white-space: normal;
}

.competitorList .athlete .athleteName {
  display: none;
}

.competitorList .athlete .athleteName.athleteScoreboardName {
  display: block;
}

.competitorList .athlete .athleteName p {
  margin: 0;
}

[font-theme='800'] .competitorList .athlete .athleteName,
[font-theme='700'] .competitorList .athlete .athleteName,
[font-theme='600'] .competitorList .athlete .athleteName {
  font-weight: var(--font-weight);
  font-size: 2.2vw;
}

.competitorList .athlete .editing input {
  box-shadow: 0 0.25rem 0.25rem -0.25rem rgba(0, 123, 255, 0.7);
}

.competitorList .athlete .rbt-menu {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  /*  transform: translate3d(0px, calc(-100% + -10px), 0px) !important;*/
  padding: 0.5rem 0.5rem;
  border-radius: 12px;
  background-clip: border-box;
  /*  max-height: 288px !important;*/
  z-index: 1002;
}

.competitorList .athlete .rbt-menu ::-webkit-scrollbar-track {
  display: none;
  border-radius: 12px;
}

/* Works on Chrome/Edge/Safari */
.competitorList .athlete ::-webkit-scrollbar {
  width: 6px;
}
.competitorList .athlete ::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px;
}
.competitorList .athlete ::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 20px;
}

.competitorList .athlete .rbt-menu .dropdown-item {
  padding: 0.35rem 0.5rem;
}

.competitorList .athlete input {
  border: none;
  background: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 1.2rem;
  /*  text-overflow: ellipsis;*/
}

[font-theme='800'] .competitorList .athlete input,
[font-theme='700'] .competitorList .athlete input,
[font-theme='600'] .competitorList .athlete input {
  font-weight: var(--font-weight);
  /*font-size: 1.3rem;*/
  font-size: 2vw;
}

.competitorList .athlete input:hover {
  background: rgba(240, 240, 240, 0.6);
}

.competitorList .athlete input:hover:disabled {
  background: transparent;
}

.competitorList .athlete input:focus {
  outline: 0;
  /*border-bottom: 1px solid blue;*/
}

.competitorList .athlete input:disabled {
  -webkit-text-fill-color: var(--text-secondary);
}

.competitorList .headshot svg {
  margin: 0 auto;
  color: #ccc;
}

.left .competitorList .score {
  background: var(--header-gradient-left);
}

.right .competitorList .score {
  background: var(--header-gradient-right);
}

[font-theme='800'] .competitorList .score,
[font-theme='700'] .competitorList .score,
[font-theme='600'] .competitorList .score {
  font-weight: var(--font-weight);
}

.competitorList .score button {
  color: var(--text-primary);
}

.right .competitorList .athlete {
  text-align: right;
}

.right .competitorList .athlete input {
  text-align: left;
}

.right .competitorList .athlete input:disabled {
  text-align: right;
}

.left .competitorList .competitorLine .highlight {
  /*box-shadow: 0 0 6px rgba(0,0,0,0.15);*/
  /*box-shadow: 0.25rem 0rem 0.75rem rgba(0,0,0,.1);*/
  box-shadow: -0.25rem 0rem 0.75rem rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s;
  /*border-radius: 12px 0 0 12px;*/
}

.right .competitorList .competitorLine .highlight {
  /*box-shadow: 0 0 6px rgba(0,0,0,0.15);*/
  /*box-shadow: -0.25rem 0rem 0.75rem rgba(0,0,0,.1);*/
  box-shadow: 0.25rem 0rem 0.75rem rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.5s;
  /*border-radius: 0 12px 12px 0;*/
}

.competitorList .competitorLine label {
  margin-bottom: 0;
  font-size: 1.4vh;
  font-style: italic;
  margin-top: -0.75vh;
  padding: 0vh 0.5vh 0 0;
  text-align: right;
  width: 95%;
  color: #fff;
  white-space: nowrap;
}

.competitorList .competitorLine .highlightshadow {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: box-shadow 0.5s;
}

.competitorList .exhibition .logo {
  background: var(--background-haze);
}

.competitorList .buttonContainer {
  background-color: var(--background);
  border-radius: 50%;
  margin: 4px auto;
  width: 32px;
  height: 32px;
  padding: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

[font-theme='800'] .competitorList .buttonContainer button .lineupNumber,
[font-theme='700'] .competitorList .buttonContainer button .lineupNumber,
[font-theme='600'] .competitorList .buttonContainer button .lineupNumber {
  font-weight: var(--font-weight);
}

.competitorList .pending {
  color: var(--text-tertiary);
  font-size: 1rem;
}

.competitorList .buttonContainer button .athletePlay {
  font-size: 2rem;
}

.competitorList .buttonContainer button .lineupNumber {
  font-size: 0.8rem;
}

@media print {
  .competitorList .buttonContainer button {
    background-color: transparent !important;
  }
  .competitorList .buttonContainer button .lineupNumber {
    color: #000 !important;
    opacity: 1 !important;
    -webkit-text-fill-color: #000 !important;
    text-fill-color: #000 !important;
  }
  .competitorList .scorecard {
    font-size: 2.4rem !important;
  }
  .competitorList .score .roundSummary .score {
    font-size: 2.4rem !important;
  }
}

.competitorList .buttonContainer button div {
  font-size: 1.5rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
}

.competitorList .buttonContainer button span {
  margin: 0 auto;
}

.competitorList .buttonContainer button {
  justify-content: center;
  display: flex;
  /*flex-direction: column;*/
  align-items: center;
  position: relative;
}

.competitorList .buttonContainer button svg {
  /*font-size: 1.5rem; */
  margin: 0 auto;
  /*color: #eee;*/
}

.competitorList button {
  border-radius: 50%;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 0.8rem;
}

.competitorList .scorecard {
  height: 100%;
  width: 100%;
  border-radius: 0;
  margin: 0;
  font-size: 1.4rem;
  text-align: center;
  border: none;
  font-variant-numeric: tabular-nums;
}

.competitorList .scorecard:hover {
  background: none;
  color: #888;
}

[font-theme='800'] .competitorList .scorecard,
[font-theme='700'] .competitorList .scorecard,
[font-theme='600'] .competitorList .scorecard {
  font-weight: var(--font-weight);
}

.competitorList .scoreSheetSpaces {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  font-size: 1rem;
}

.competitorList .scoreSheetSpaces .col {
  padding: 0.5rem;
}

@media (max-width: 375px) {
  .competitorList .competitorLine label {
    font-size: 1vh;
  }
}
@media (max-width: 576px) {
  .competitorList .competitorLine label {
    font-size: 1.1vh;
  }
}

@media (max-width: 768px) {
  .competitorList .competitorLine label {
    font-size: 1vh;
  }

  .competitorList .logo .buttonContainer button {
    width: 24px;
    height: 24px;
  }
}

.evalSlabCL {
  width: 33%;
}

.videoSetupContainer .videoQRbutton {
  margin: 0 auto;
  width: 100%;
  display: flex;
}

.videoSetupContainer .row {
  margin: 1rem auto;
  max-width: 60vh;
}

.videoSetupSelector .sideScrollMenuContainer {
  padding: 0;
}

.videoSetupSelector .sideScrollMenuButton {
  border-radius: 3rem;
  width: 3rem;
  height: 3rem;
  color: #666;
  margin: 0.2rem;
  display: flex;
  position: relative;
}

.videoSetupSelector .sideScrollMenuButton .camIconNum {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoSetupSelector .sideScrollMenuButton svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.8rem;
  height: 1.8rem;
}

.videoSetupSelector .sideScrollMenuButton .camIndex {
  position: absolute;
  padding-right: 0.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80%;
  font-weight: bold;
}

.videoSetupContainer input {
  cursor: pointer;
}

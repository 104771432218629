.meanCountdown {
  background: url('/public/media/super16/background_w_logo_no_title.jpg');
  background-size: cover;
}

.sprouts {
  background: url('/public/media/sprouts/sprouts_background.jpg');
  background-size: cover;
}

.arkansas {
  background: url('/public/media/arkansas/hog_background.jpg');
  background-size: cover;
}

.michiganW {
  background: url('/public/media/michigan/w_background.jpg');
  background-size: cover;
}

.scoreboardDisplay .bigten {
  background: url('/public/media/bigten/btn_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.bigten {
  background: url('/public/media/bigten/btn_background_only.jpg');
}

.USAGCC {
  background: url('/public/media/usagcc/usagcc_background.jpg');
  background-size: cover;
}

.scoreboardDisplay .GEC {
  background: url('/public/media/gec/gec_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .MAC {
  background: url('/public/media/mac/mac_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .ACC {
  background: url('/public/media/acc/acc_background.jpg');
  background-size: cover;
}

.scoreboardDisplay .MIC {
  background: url('/public/media/mic/mic_background.jpg');
  background-size: cover;

  .byVirtius {
    color: black;
  }
}

.scoreboardDisplay .big12 {
  background: url('/public/media/big12/big12_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .SEC {
  background: url('/public/media/sec/sec_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .EAGL {
  background: url('/public/media/eagl/eagl_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .MW {
  background: url('/public/media/mw/mw_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .NCGA {
  background: url('/public/media/ncga/ncga_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .ArkRegional {
  background: url('/public/media/arkregional/arkregional_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .CalRegional {
  background: url('/public/media/calregional/calregional_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .WashRegional {
  background: url('/public/media/washregional/washregional_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .AlaRegional {
  background: url('/public/media/alaregional/alaregional_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .UtahRegional {
  background: url('/public/media/utahregional/utahregional_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .NCAAChampsW {
  background: url('/public/media/ncaaw/ncaaw_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .ECAC {
  background: url('/public/media/ecac/ecac_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .USAGCN {
  background: url('/public/media/usagcn/usagcn_background.jpg');
  background-size: cover;

  .byVirtius {
    color: #162c4f;
  }
}

.scoreboardDisplay .sprouts {
  background: url('/public/media/sprouts/sprouts_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .wasatch {
  background: url('/public/media/wasatch/wasatch_background.jpg');
  background-size: cover;

  .byVirtius {
    color: white;
  }
}

.scoreboardDisplay .bestofutah {
  background: url('/public/media/bestofutah/bestofutah_background.jpg');
  background-size: cover;

  .byVirtius {
    color: black;
  }
}

.scoreboardDisplay .utah {
  background: url('/public/media/utah/utah_background.jpg');
  background-size: cover;

  .byVirtius {
    color: black;
  }
}

.scoreboardDisplay .arkansas .zoom,
.scoreboardDisplay .michiganW .zoom,
.scoreboardDisplay .bigten .zoom,
.scoreboardDisplay .USAGCC .zoom,
.scoreboardDisplay .GEC .zoom,
.scoreboardDisplay .MAC .zoom,
.scoreboardDisplay .ACC .zoom,
.scoreboardDisplay .MAC .zoom,
.scoreboardDisplay .SEC .zoom,
.scoreboardDisplay .MIC .zoom,
.scoreboardDisplay .MW .zoom,
.scoreboardDisplay .NCGA .zoom,
.scoreboardDisplay .EAGL .zoom,
.scoreboardDisplay .big12 .zoom,
.scoreboardDisplay .ArkRegional .zoom,
.scoreboardDisplay .CalRegional .zoom,
.scoreboardDisplay .UtahRegional .zoom,
.scoreboardDisplay .AlaRegional .zoom,
.scoreboardDisplay .WashRegional .zoom,
.scoreboardDisplay .NCAAChampsW .zoom,
.scoreboardDisplay .ECAC .zoom,
.scoreboardDisplay .USAGCN .zoom,
.scoreboardDisplay .arkansas .byVirtius,
.scoreboardDisplay .michiganW .byVirtius,
.scoreboardDisplay .sprouts .zoom,
.scoreboardDisplay .wasatch .zoom,
.scoreboardDisplay .bestofutah .zoom,
.scoreboardDisplay .utah .zoom {
  display: none !important;
}

.meanCountdown .matchDate,
.meanCountdown .matchName,
.meanCountdown .matchTitle,
.meanCountdown .byVirtius {
  color: #090926 !important;
}

.meanCountdown .byVirtius svg,
.byVirtius svg {
  height: 10rem;
}

.matchCountdown {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: black;
  font-size: 1rem;
  display: flex;
  color: #eee;
}

.matchCountdown .zoom {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.matchCountdown .matchName {
  text-align: center;
  color: #fff;
  font-weight: 500;
  width: 100%;
  display: block;
  font-size: 2rem;
}

.matchCountdown .heroMessage {
  margin: 0 auto;
}

.matchCountdown .heroMessage p {
  color: #ccc;
}

.matchCountdown .heroMessage .matchLogos {
  background: radial-gradient(#eee, transparent 100%);
  margin: 1rem auto;
}

.countdownTimer {
  text-align: center;
  color: #eee;
  font-variant-numeric: tabular-nums;
  width: 100%;
  font-size: 1.2rem;
}

.countdownTimer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-type: decimal-leading-zero;
}

.countdownTimer ul li {
  display: inline;
}

.matchCountdown .matchButton {
  opacity: 1 !important;
  padding: 0;
}

.byVirtius {
  width: 100%;
  color: #666;
  z-index: 1001;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  margin: 1rem auto;
  font-weight: bold;
  position: absolute;
  bottom: 0;
}

.heroMessage .matchLogos img {
  object-fit: contain;
  /*  max-width: 80px;*/
  max-width: 10vw;
  max-height: 10vh;
}

.scoreboardDisplay .byVirtius {
  font-size: 2rem;
}

@media (max-width: 375px) {
  .matchCountdown .matchName {
    font-size: 1em;
  }
  .matchCountdown .heroMessage p,
  .countdownTimer {
    font-size: 0.8em;
  }
  .heroMessage .matchLogos img {
    /*    height: 40px;*/
  }
  .matchCountdown .heroMessage .matchLogos {
    margin: 0 auto;
  }
  .dualPlayerWrapper .matchLogos {
    display: none;
  }
  .byVirtius {
    margin: 0;
    font-size: 0.6rem;
  }
  .matchCountdown .heroMessage .matchLogos img {
    height: 40px;
  }
  .matchCountdown .heroMessage .matchButton {
    min-height: 60px;
  }
  .heroMessage .matchLogos img {
    height: 60px;
  }
}

@media (min-width: 375px) {
  .matchCountdown .matchName {
    font-size: 1em;
  }
  .matchCountdown .heroMessage p,
  .countdownTimer {
    font-size: 0.8em;
  }
  .heroMessage .matchLogos img {
    /*    height: 40px;*/
  }
  .matchCountdown .heroMessage .matchLogos {
    margin: 0rem auto;
  }
  .dualPlayerWrapper .matchLogos {
    display: none;
  }
  .byVirtius {
    margin: 0.1rem auto;
    font-size: 0.7rem;
  }
}

@media (min-width: 576px) {
  .matchCountdown .matchName {
    font-size: 1.4em;
  }
  .matchCountdown .heroMessage p,
  .countdownTimer {
    font-size: 1.2em;
  }
  .heroMessage .matchLogos img {
    /*    height: 60px;*/
  }
  .matchCountdown .heroMessage .matchLogos {
    margin: 1rem auto;
  }
  .dualPlayerWrapper .matchLogos {
    display: none;
  }

  .byVirtius {
    margin: 1rem auto;
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .matchCountdown .matchName {
    font-size: 1.8em;
  }
  .matchCountdown .heroMessage p,
  .countdownTimer {
    font-size: 1.2em;
  }
  .heroMessage .matchLogos img {
    height: 80px;
  }
  .matchCountdown .heroMessage .matchLogos {
    margin: 1rem auto;
  }
  .dualPlayerWrapper .matchLogos {
    display: none;
  }
  .byVirtius {
    margin: 1rem auto;
  }
}

@media (min-width: 992px) {
  .matchCountdown .matchName {
    font-size: 2em;
  }
  .matchCountdown .heroMessage p,
  .countdownTimer {
    font-size: 1.2em;
  }
  .heroMessage .matchLogos img {
    height: 80px;
  }
  .matchCountdown .heroMessage .matchLogos {
    margin: 1rem auto;
  }
  .dualPlayerWrapper .matchLogos {
    display: block;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}

@media (min-width: 1920px) {
}

@media (min-width: 2560px) {
}

.athlete-batch-typeahead {
  position: relative;
  width: 100%;
}

.athlete-batch-typeahead .input-container {
  position: relative;
}

.athlete-batch-typeahead .names-list {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  max-height: calc(65vh);
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.athlete-batch-typeahead .names-list h6 {
  color: #495057;
}

.athlete-batch-typeahead .names-list .btn-primary {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}

.athlete-batch-typeahead .names-list ul {
  margin: 0;
  padding: 0;
}

.athlete-batch-typeahead .names-list li {
  padding: 0 0.5rem;
  border-radius: 3px;
  border-bottom: 1px solid #eee;
}

.athlete-batch-typeahead .mb-3 {
  margin-bottom: 0rem !important;
}

.athlete-batch-typeahead .names-list .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.athlete-batch-typeahead .names-list .buttons-container {
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.athlete-batch-typeahead .name-input {
  font-weight: 500;
  min-width: 150px;
  height: 30px;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
}

.athlete-batch-typeahead .athlete-number {
  min-width: 2rem;
  text-align: right;
  color: #6c757d;
  font-weight: 500;
  padding-right: 0.5rem;
}

.athlete-batch-typeahead .skip-checkbox {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #6c757d;
}

.athlete-batch-typeahead .added-indicator {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #198754;
}

.athlete-batch-typeahead .added-indicator input[type="checkbox"] {
  cursor: default;
}

.athlete-batch-typeahead .skipped {
  opacity: 0.5;
}

.athlete-batch-typeahead .skipped .skip-checkbox {
  opacity: 1;
}

.athlete-batch-typeahead .matches-container {
  flex-grow: 1;
}

.athlete-batch-typeahead .match-option {
  padding: 0.125rem 0.25rem;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  white-space: nowrap;
}

.athlete-batch-typeahead .match-option .match-content {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1rem;
}

.athlete-batch-typeahead .match-option .team-names {
  font-size: 0.75rem;
  color: #6c757d;
  padding-left: 1rem;
}

.athlete-batch-typeahead .match-option input[type='radio'] {
  margin: 0;
  width: 0.75rem;
  height: 0.75rem;
}

.athlete-batch-typeahead .matches-container {
  overflow-x: auto;
  padding: 0.5rem 0;
}

.athlete-batch-typeahead .match-option.selected {
  background: #e9ecef;
  border-color: #ced4da;
}

.athlete-batch-typeahead .match-option:hover {
  background: #f8f9fa;
}

.athlete-batch-typeahead .names-list li:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.athlete-batch-typeahead .single-result-item {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.athlete-batch-typeahead .single-result-item .athlete-name {
  font-weight: 500;
}

.athlete-batch-typeahead .single-result-item .team-names {
  color: #6c757d;
  font-size: 0.875rem;
}

.teamRoster .rosterSelection .rosterTitles {
  padding-bottom: 0;
}

.clipForm label,
.clipForm input {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.clipFormRow .innerRow {
  margin-left: 0;
  margin-right: 0;
}

.clipFormRow .statusLabel {
  font-variant-numeric: tabular-nums;
}

.videoPlayer {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.videoPlayer video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.date-picker-pill {
  display: inline-block !important;
}

.react-datepicker-wrapper {
  display: inline-block !important;
}

.react-datepicker__input-container {
  display: inline-block !important;
}

.rounded-pill {
  height: 26.59px !important;
  line-height: 1 !important;
  white-space: nowrap !important;
}

.btn-link.ms-1 {
  font-size: 16px;
  line-height: 1;
  color: #6c757d;
  padding: 0 6px !important;
  margin-left: 2px !important;
  border-radius: 50%;
  height: 26.59px;
  width: 26.59px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-link.ms-1:hover {
  background-color: rgba(108, 117, 125, 0.1);
  color: #495057;
  text-decoration: none;
}

.heroMessage {
  padding: 0;
  margin: 1.5rem auto 1rem;
}

.heroMessage .matchTitle {
  font-size: 1.2rem;
  color: #666;
  font-weight: 600;
  margin: 0;
}

.heroMessage .matchDate {
  color: #666;
  font-weight: 300;
  margin: 0;
}

.heroMessage .matchLogos .matchButton img {
  height: 80px;
  border: none;
  margin: 1rem 0.5rem;
  max-width: 80px;
}

@media (max-width: 576px) {
  .heroMessage .matchLogos .matchButton img {
    max-height: 64px;
    border: none;
    margin: 1rem 0.5rem;
    max-width: 64px;
  }
}

@media (max-width: 375px) {
  .heroMessage .matchLogos .matchButton img {
    max-height: 64px;
    border: none;
    margin: 1rem 0.5rem;
    max-width: 64px;
  }
}

.heroMessage .matchLogos {
  width: 100%;
}

.heroMessage .matchButton {
  border: none;
  border-radius: 1rem;
  min-height: 100px;
}

.heroMessage .calendarAdd {
  width: 100%;
}

.heroMessage .streamLinks {
  margin-bottom: 1rem;
}

.heroMessage .streamLinkTitle {
  color: #666;
  font-size: 1rem;
}

.heroMessage .streamLinks button {
  color: #666;
}

.heroMessage .streamLinks button:hover:enabled {
  transform: scale(1.1);
  transition: transform 0.3s;
}

.heroMessage .tagPills {
  margin: 0.5rem 0;
}

.heroMessage .tagPill {
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  margin: 0 0.25rem;
  font-size: 0.875rem;
  display: inline-block;
}

.heroMessage .tagPill.mens {
  background: #1a4b8c;
  color: white;
}

.heroMessage .tagPill.womens {
  background: #663399;
  color: white;
}

.heroMessage .tagPill.streaming {
  background: #ff3b30;
  color: white;
}

.heroMessage .tagPill.default {
  background: #e9ecef;
  color: #666;
}

.uploadVideoPreviewModal .modal-content {
  border-radius: 25px;
}

.uploadVideoPreviewFooter .closeButton,
.uploadVideoPreviewFooter .uploadButton {
  height: 40px;
  width: 50%;
  min-width: 240px;
  margin: 0 auto;
  border-radius: 50px;
}

.uploadVideoPreviewHeader {
  font-weight: 600;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #333;
  position: relative;
}

.uploadVideoPreviewHeader span {
  /*padding-left: 2.4rem;*/
}

.uploadVideoPreviewHeader .headerName {
  float: right;
  font-weight: 400;
  font-style: italic;
  color: #666;
}

.uploadVideoPreviewFooter {
  position: relative;
}

.uploadVideoPreviewFooter .progress {
  position: absolute;
  top: 0;
  margin: 0;
  left: 0;
  height: 2%;
  width: 100%;
  background-color: transparent;
}

.sessionRoutines .accordion {
  margin: 1rem 0;
}

.sessionRoutines .card-header {
  border-bottom: none;
  cursor: pointer;
}

.sessionRoutines .card-header p {
  margin: 0 auto;
}

.sessionRoutines .card-body .row {
  align-items: center;
}

.sessionRoutines input {
  padding: 0;
}

.sessionRoutines .form-control {
  border: none;
  color: #007bff;
  height: auto;
}

:root {
  --gutter: 20px;
  --background: #fff;
  --background-haze: #f8f8f8;
  --header-gradient: linear-gradient(90deg, #f8f8f8, white, #f8f8f8);
  --header-gradient-left: linear-gradient(90deg, #f8f8f8, #fff);
  --header-gradient-right: linear-gradient(90deg, #fff, #f8f8f8);
  --text-primary: #222;
  --text-primary-hover: #212529;
  --text-secondary: #495057;
  --text-tertiary: #666;
  --text-quaternary: #999;
  --accent: purple;
}
[data-theme='dark'] {
  --background: #000;
  --background-haze: #222;
  /*--header-gradient: var(--background-haze);
  --header-gradient-left: var(--background-haze);
  --header-gradient-right: var(--background-haze);*/
  --header-gradient: linear-gradient(90deg, #222, #000, #222);
  --header-gradient-left: linear-gradient(90deg, #222, #000);
  --header-gradient-right: linear-gradient(90deg, #000, #222);
  --text-primary: #fff;
  --text-primary-hover: #ccc;
  --text-secondary: #eee;
  --text-tertiary: #ddd;
  --text-quaternary: #aaa;
  --accent: darkred;
}

[data-theme='michigan'] {
  --background: #00274c;
  --background-haze: #00274c;
  /*--header-gradient: var(--background-haze);
  --header-gradient-left: var(--background-haze);
  --header-gradient-right: var(--background-haze);*/
  --header-gradient: linear-gradient(90deg, #00274c, #00274c, #00274c);
  --header-gradient-right: linear-gradient(90deg, #00274c, #00274c);
  --header-gradient-left: linear-gradient(90deg, #00274c, #00274c);
  --text-primary: #fff;
  --text-primary-hover: #ccc;
  --text-secondary: #eee;
  --text-tertiary: #ddd;
  --text-quaternary: #aaa;
  --accent: darkred;
}

[data-theme='transparent'] {
  --background: transparent;
  --background-solid: #fff;
}

[font-theme='800'] {
  --font-weight: 800;
}

[font-theme='700'] {
  --font-weight: 700;
}

[font-theme='600'] {
  --font-weight: 600;
}

[data-theme='dark'] .form-control {
  color: var(--text-secondary);
}

[data-theme='dark'] hr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

body {
  background-color: var(--background) !important;
  /*transition: background-color 0.5s;*/
}

.vBlue {
  color: #00a2ff !important;
}

.vGray {
  color: #5e5e5e !important;
}

.competitorList .buttonContainer .forceVisible {
  opacity: 1 !important;
}

.loginBubble {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
}

.homeText {
  font-size: 1rem;
  padding-bottom: 72px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #f8f8f8, white, #f8f8f8);
  min-height: 36px;
  z-index: 101;
}

.footer ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.footer ul li,
.footer ul li a {
  font-size: 0.8rem;
  margin: 0 0.8rem;
  color: #666;
  text-align: center;
  white-space: nowrap;
}

.menuBubble {
  border-radius: 4px 24px 24px 4px;
  border: 1px solid #f8f8f8;
  float: left;
  height: 42px;
  width: 42px;
  display: flex;
  background: #f8f8f8;
  z-index: 101;
  position: absolute !important;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.menuBubble svg {
  font-size: 1.5rem;
}

#dropdown-menuBubble {
  padding: 0;
  background: #f8f8f8;
  border-radius: 4px 24px 24px 4px;
  height: 100%;
  width: 100%;
  font-size: 1rem;
}

.menuBubble-menu {
  transform: translate3d(48px, -1px, 0px) !important;
  border-radius: 25px 6px 6px 25px !important;
  /*border-right: none;
  border-top: 1px solid rgba(0,0,0,.15) !important; */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 0 !important;
  overflow: hidden;
  min-width: 6rem !important;
  z-index: 100;
}

.menuBubble-menu .dropdown-item {
  color: #aaa;
  font-size: 1rem;
  text-align: left;
  /*border-radius: 25px 6px 6px 25px !important;*/
  height: 40px;
}

.menuBubble-menu .dropdown-item:hover,
.userBubble-menu .dropdown-item:hover,
.roundBubble-menu .dropdown-item:hover {
  color: #888;
}

.menuBubble-menu .dropdown-item:active,
.userBubble-menu .dropdown-item:active,
.roundBubble-menu .dropdown-item:active {
  color: #888;
  background: transparent;
}

.menuBubble-menu .dropdown-item:focus,
.userBubble-menu .dropdown-item:focus,
.roundBubble-menu .dropdown-item:focus {
  outline: 0;
  /*box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);*/
}

.userBubble {
  border-radius: 24px 4px 4px 24px;
  border: 1px solid #f8f8f8;
  float: right;
  height: 42px;
  width: 42px;
  display: flex;
  background: #f8f8f8;
  z-index: 101;
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

#dropdown-userBubble {
  padding: 0;
  background: #f8f8f8;
  border-radius: 24px 4px 4px 24px;
  height: 100%;
  width: 100%;
  font-size: 1rem;
}

#dropdown-userBubble:hover,
#dropdown-menuBubble:hover {
  background: #ccc;
}

#dropdown-roundBubble:hover {
}

/*#dropdown-roundBubble::after,*/
#dropdown-userBubble::after,
#dropdown-menuBubble::after {
  display: none;
}

.userBubble-menu {
  transform: translate3d(-110px, -1px, 0px) !important;
  border-radius: 6px 25px 25px 6px !important;
  /*border-right: none;
  border-top: 1px solid rgba(0,0,0,.15) !important; */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 0 !important;
  overflow: hidden;
  min-width: 6rem !important;
}

.userBubble-menu .dropdown-item {
  color: #aaa;
  font-size: 1rem;
  text-align: right;
  /*border-radius: 6px 25px 25px 6px !important;*/
  height: 40px;
}

.loginContainer,
.startContainer {
  margin: 0 auto;
  margin-top: 35vh;
}

.loginContainer button,
.startContainer button {
  font-size: 1rem;
  width: 50%;
  min-width: 100px;
  border-radius: 50px;
  color: #aaa;
  border-color: #aaa;
}

.loginModal .modal-content,
.createOrJoinModal .modal-content,
.setupModal .modal-content,
.videoSetupModal .modal-content {
  border-radius: 25px;
  /*min-width: calc(375px - 0.5rem);*/
  /*transition: flex 0.3s ease-out;*/
}

.setupModal.fullHeight .modal-content {
  min-height: calc(100vh - 3.5rem);
}

.loginSocial,
.loginForm,
.createOrJoinForm {
  display: flex;
  flex-wrap: wrap;
  /*min-width: 375px;*/
}

.loginSocial .icon,
.loginSocial .label {
  height: 100%;
  padding: 0;
}

.loginSocial .label {
  text-align: left;
}

.loginForm .passwordSpecs,
.loginForm .codeSpecs {
  font-size: 0.75rem;
  margin: 0.5rem auto;
  width: 66%;
}

.createOrJoinForm .codeSpecs {
  font-size: 0.75rem;
  margin: 0.5rem auto;
  width: 100%;
}

.loginForm form,
.createOrJoinForm form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.loginForm .password-container {
  position: relative;
  width: 66%;
  margin: 0 auto;
}

.loginForm .password-container input {
  width: 100%;
}

.loginForm .password-container i {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 40px;
  cursor: pointer;
  color: #888;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loginForm .password-container svg {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0 auto;
}

.loginForm .alert,
.createOrJoinForm .alert {
  width: 66%;
  margin: 0 auto;
  font-size: 0.9rem;
  padding: 10px;
  text-align: center;
}

.alert-dismissible .close {
  padding: 10px !important;
  font-size: 0.9rem;
  line-height: inherit;
}

.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 100px;
  position: absolute;
  right: 1rem;
  transform: translate(0, -50%);
  top: 50%;
  height: 36px;
  justify-content: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.search-container .searchIconFiller {
  min-width: 3em;
}

.search-container button {
  border: none;
  background: none;
  border-radius: 100px;
  padding: 0 1em;
  color: rgba(150, 150, 150, 1);
  display: flex;
  min-width: 3em;
}

.search-container button:focus {
  outline: 0;
}

.search-container .spinner-border {
  color: rgba(220, 220, 220, 1);
}

.icon-only {
  width: 36px;
  background: #eee;
  box-shadow: none;
  cursor: pointer;
}

.search-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(150, 150, 150, 1);
  padding: 0 8px;
}

.search-input-container {
  width: 100%;
}

.search-container .hidden {
  display: none;
}

.search-input-container input {
  border-radius: 5px;
  width: 100%;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  border: none;
  font-size: 1rem;
}

.search-input-container input::placeholder {
  color: #d3d3d3;
}

.search-input-container input:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.search-input-container input:focus::placeholder {
  color: #d3d3d3;
}

.adminForm .alert,
.setupForm .alert {
  width: 100%;
  margin: 0 auto;
  font-size: 0.9rem;
  padding: 10px;
  text-align: center;
}

.loginForm .form-error,
.createOrJoinForm .form-error,
.adminForm .form-error {
  float: right;
  color: #dc3545;
}

.createOrJoinForm .setupFormLine,
.adminForm .createOrJoinForm .setupFormLine {
  width: 66%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.modal-dialog .modal-footer .row {
  margin: 8px 0;
}

.adminForm .setupFormLine {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.adminForm .nav-tabs {
  width: 100%;
}

.adminForm .tab-content {
  width: 100%;
}

.adminForm .nav-tabs .nav-link {
  border-radius: 0.5rem 0.5rem 0 0;
}

.adminForm .athleteProfile,
.adminForm .teamProfile,
.adminForm .leagueProfile {
  padding: 0;
}

.adminForm .leagueProfile .btn.disabled,
.adminForm .leagueProfile .btn:disabled,
.adminForm .leagueProfile select,
.adminForm .teamProfile .btn.disabled,
.adminForm .teamProfile .btn:disabled,
.adminForm .teamProfile select,
.adminForm .athleteProfile .btn.disabled,
.adminForm .athleteProfile .btn:disabled,
.adminForm .athleteProfile select {
  opacity: 1 !important;
}

.adminForm .createOrJoinForm input.disabled,
.adminForm .createOrJoinForm input:disabled,
.adminForm .createOrJoinForm .btn.disabled,
.adminForm .createOrJoinForm .btn:disabled,
.adminForm .createOrJoinForm select.disabled,
.adminForm .createOrJoinForm select:disabled {
  /*opacity: 0.8 !important;*/
}

.adminForm .createOrJoinForm input.disabled,
.adminForm .createOrJoinForm input:disabled,
.adminForm .createOrJoinForm select.disabled,
.adminForm .createOrJoinForm select:disabled,
.adminForm .sessionStreams select.disabled,
.adminForm .sessionStreams select:disabled {
  background-color: inherit;
  cursor: auto;
  color: #212529;
}

.adminForm .sessionStreams select {
  height: auto;
  padding: 0.25rem;
  background: rgba(230, 230, 230, 0.75);
}

.adminForm
  .createOrJoinForm
  .custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.8);
  cursor: default;
}

.adminForm
  .createOrJoinForm
  .custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::after,
.adminForm
  .createOrJoinForm
  .custom-control-input:disabled
  ~ .custom-control-label::after,
.adminForm
  .createOrJoinForm
  .custom-control-input[disabled]
  ~ .custom-control-label::after,
.adminForm .createOrJoinForm .custom-switch .custom-control-label::after,
.adminForm
  .createOrJoinForm
  .custom-control-input:disabled
  ~ .custom-control-label::before,
.adminForm
  .createOrJoinForm
  .custom-control-input[disabled]
  ~ .custom-control-label::before {
  cursor: default;
}

.adminForm .leagueProfile .col:focus,
.adminForm .teamProfile .col:focus,
.adminForm .athleteProfile .col:focus {
  outline: 0;
}

.adminForm .leagueProfile select,
.adminForm .teamProfile select,
.adminForm .athleteProfile select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.adminForm .leagueProfile input:disabled,
.adminForm .teamProfile input:disabled,
.adminForm .athleteProfile input:disabled {
  border: none;
}

.adminForm .leagueProfile .rbt-token-disabled,
.adminForm .athleteProfile .rbt-token-disabled,
.adminForm .teamProfile .rbt-token-disabled {
  background-color: #e7f4ff;
  color: #007bff;
}

.adminForm .leagueProfile .rbt-input-multi.form-control[disabled],
.adminForm .teamProfile .rbt-input-multi.form-control[disabled],
.adminForm .athleteProfile .rbt-input-multi.form-control[disabled] {
  background-color: inherit;
  border: none;
}

.adminForm .teamRoster .rosterSelection table tr:hover {
  cursor: default;
}

.adminForm .teamRoster .rosterSelection .disabled table tr:hover {
  background: none;
}

.adminForm .teamRoster .rtnInput input.disabled,
.adminForm .teamRoster .rtnInput input:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  cursor: default;
  border: none;
}

.adminForm .teamRoster .rtnInput input {
  width: 5rem;
}

.adminForm .teamRoster select.disabled,
.adminForm .teamRoster select:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  cursor: default;
}

.adminForm .teamRoster .apparatusToggle label:active:disabled,
.adminForm .teamRoster .apparatusToggle label.active.disabled {
  opacity: 0.8 !important;
  background-color: #6c757d !important;
  color: #333;
}

.adminForm .teamRoster .apparatusToggle label.disabled,
.adminForm .teamRoster .apparatusToggle label:disabled {
  background: inherit !important;
  color: inherit;
  opacity: 0.8 !important;
}

.adminForm .teamRoster .apparatusToggle label:hover {
  opacity: 0.9;
  background-color: #fff;
  color: #333;
}

.adminForm .teamRoster .apparatusToggle label:active,
.adminForm .teamRoster .apparatusToggle label.active {
  opacity: 0.9;
  background-color: #6c757d;
  color: #333;
}

.adminForm .teamRoster .rosterTitles {
  bottom: 0rem;
  left: 0;
  padding-bottom: 1rem;
  background: #fff;
  z-index: 1;
}

.adminForm .teamRoster .rosterInputForms {
  position: sticky;
  position: -webkit-sticky;
  bottom: -1rem;
  left: 0;
  padding-bottom: 1rem;
  background: #fff;
  z-index: 1;
  display: flex;
}

.adminForm .teamRoster .rosterInputForms .rbt {
  width: 100%;
}

.adminForm .typeahead {
  background: rgba(250, 250, 250, 0.75);
}

.adminForm .typeahead .form-control {
  padding: 0 0.75rem;
  background: rgba(250, 250, 250, 0.75);
}

.adminForm .typeahead .rbt-token {
  height: 36px;
  margin: 3px 3px 0 0;
  line-height: 1.5rem;
}

.adminForm .typeahead .dropdown-menu {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #aaa;
}

.adminForm .teamRoster .checkbox {
  height: auto;
  width: auto;
}

.adminForm .teamRoster .form-control {
  border: 0;
  height: auto;
  cursor: pointer;
}

.setupForm,
.scoreboardBody {
  overflow-x: hidden;
}

.setupForm .table-responsive,
.scoreboardBody .table-responsive {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.setupForm .table-responsive::-webkit-scrollbar,
.scoreboardBody .table-responsive::-webkit-scrollbar {
  width: 0 !important;
}

.setupForm .form-control {
  cursor: pointer;
  padding: 0;
}

.setupForm .table td,
.adminForm .table td,
.scoreboardBody .table td {
  white-space: break-spaces;
}

.adminForm .teamRoster .table td {
  vertical-align: middle;
}

.createOrJoinForm .teamSelector {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  height: 100%;
  z-index: 10;
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  overflow-y: scroll;
  background: rgba(230, 230, 230, 0.8);
}

.createOrJoinForm .empty {
  height: 0;
}

.linkButton:hover:disabled {
  text-decoration: none;
}

.createOrJoinForm .teamSelectorReturn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
}

.createOrJoinForm .teamSelectorReturn:hover {
  background: rgba(230, 230, 230, 0.6);
}

.createOrJoinForm .teamSelector .row {
  margin: 0;
  position: relative;
}

.createOrJoinForm .teamSelector .card-body {
  padding: 0;
}

.createOrJoinForm .teamSelector .card-body .card-title {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0 0.25rem;
  color: #21252a;
}

.createOrJoinForm .teamSelector button {
  width: 100%;
  border-radius: 0;
}

.createOrJoinForm .teamSelector .card-img,
.createOrJoinForm .teamSelector .card-img-top,
.createOrJoinForm .teamSelector .card-img-bottom {
  width: 66%;
  object-fit: scale-down;
  margin: 0 auto;
  padding: 12px 0;
  aspect-ratio: 1;
}

.createOrJoinForm .teamSelector .sideright {
  margin-left: 30px;
  min-height: 100%;
}

.createOrJoinForm .teamSelector .sideleft {
  margin-right: 30px;
  min-height: 100%;
}

.createOrJoinForm .teamSelector .list-group-item {
  padding: 0;
}

.createOrJoinForm select,
.adminForm select {
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 10px;
  height: 40px;
  background: rgba(250, 250, 250, 0.75);
  border: 1px solid #aaa;
  cursor: pointer;
  font-size: 0.8rem;
}

.createOrJoinForm .apparatusToggle {
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0rem 1rem 0rem 0rem;
}

.createOrJoinForm #rtnId {
  font-size: 0.8rem;
}

.createOrJoinForm .react-datepicker-wrapper {
  width: 100%;
}

.createOrJoinForm .react-datepicker-wrapper input,
.createOrJoinForm .rbt input,
.createOrJoinForm .rbt .dropdown-item {
  width: 100%;
  font-size: 0.8rem;
  /*height: 1rem;*/
  cursor: pointer;
}

.createOrJoinForm .react-datepicker-wrapper input:disabled {
  cursor: auto;
}

.createOrJoinForm .react-datepicker-popper {
  z-index: 10;
}

.createOrJoinForm .react-datepicker {
  display: flex;
}

.createOrJoinForm .react-datepicker__time-container {
  box-sizing: content-box;
}

.createOrJoinForm .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.createOrJoinForm .react-datepicker__day--selected,
.createOrJoinForm .react-datepicker__day--keyboard-selected,
.createOrJoinForm .react-datepicker__month-text--keyboard-selected,
.createOrJoinForm .react-datepicker__quarter-text--keyboard-selected,
.createOrJoinForm .react-datepicker__year-text--keyboard-selected,
.createOrJoinForm
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #007bff;
  border-color: #007bff;
  font-weight: normal;
}

.createOrJoinForm .react-datepicker__day--selected:hover,
.createOrJoinForm .react-datepicker__day--keyboard-selected:hover,
.createOrJoinForm .react-datepicker__month-text--keyboard-selected:hover,
.createOrJoinForm .react-datepicker__quarter-text--keyboard-selected:hover,
.createOrJoinForm
  .react-datepicker__year-text--keyboard-selected:hover
  .createOrJoinForm
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #007bff;
}

.createOrJoinForm
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.apparatusToggle label:hover,
.createOrJoinForm .apparatusToggle label:hover,
.adminForm .apparatusToggle label:hover {
  opacity: 0.9;
  background-color: #f0f0f0;
  color: #333;
}

.adminForm .createOrJoinForm .apparatusToggle label.active.disabled {
  background-color: rgba(0, 123, 255, 0.8);
  color: #fff;
  opacity: 1 !important;
  cursor: default;
}

.adminForm .apparatusToggle label.active.disabled {
  opacity: 0.5 !important;
  background-color: #007bff;
  color: #333;
}

.createOrJoinForm .apparatusToggle label.disabled,
.adminForm .apparatusToggle label.disabled {
  opacity: 0.5 !important;
  background-color: transparent;
  color: #007bff;
}

.createOrJoinForm .setupFormLine .col {
  padding: 0;
}

.createOrJoinForm .vsCol {
  position: relative;
  height: 100%;
}

.createOrJoinForm .disabled {
  opacity: 0.5 !important;
}

.createOrJoinForm .vs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 600;
  font-size: 1.2rem;
}

.createOrJoinForm .teamSelect {
  width: 100%;
  border: 1px solid #aaa;
  min-height: 120px;
  line-height: 1;
  aspect-ratio: 1;
}

.createOrJoinForm .teamSelect:hover {
  border-color: #aaa;
}

.createOrJoinForm .teamSelect.disabled,
.createOrJoinForm .teamSelect:disabled {
  border-color: #f8f9fa;
}

.adminForm .logoSelect {
  width: 100%;
  font-size: 5rem;
  border: 1px solid #aaa;
  display: flex;
  justify-content: center;
  min-height: 120px;
  /*line-height: 1;*/
}

.adminForm .logoSelect:hover {
  border: 1px solid #aaa;
}

.adminForm .logoSelect div {
  font-size: 1rem;
  width: 100%;
  flex-direction: column;
}

.adminForm .colorSelect {
  width: 22%;
  font-size: 5rem;
  margin-left: 4%;
  height: 30px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
}

.adminForm .form-control {
  border: 1px solid #aaa;
}

.adminForm select {
  padding-right: 5px;
}

.createOrJoinForm .setupFormLine .col label {
  width: auto;
  cursor: pointer;
}

.createOrJoinForm .custom-switch input:disabled {
  cursor: default;
}

.createOrJoinForm .custom-control-input {
  width: 100%;
}

.createOrJoinForm .custom-control-label {
  font-size: 1rem;
}

.createOrJoinForm .custom-control-label::before,
.createOrJoinForm .custom-control-label::after {
  cursor: pointer;
  font-size: 1rem;
}

.createOrJoinForm .custom-control-label.disabled::before,
.createOrJoinForm .custom-control-label.disabled::after {
  cursor: default;
}

.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  cursor: default;
}

.loginForm input,
.createOrJoinForm input {
  margin: 0 auto;
  height: 40px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
  background: rgba(250, 250, 250, 0.75);
}

.loginForm input {
  width: 66%;
}

.createOrJoinForm input {
  width: 100%;
}

.adminForm input {
  width: 100%;
  margin: 0 auto;
  height: 40px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
  background: rgba(250, 250, 250, 0.75);
}

.adminForm .batchAdd {
  padding: 0;
}

.adminForm .batchAdd input[type='checkbox'] {
  margin: 0;
  width: auto;
  height: 100%;
  padding-left: 0;
}

.adminForm .batchAdd label {
  padding-left: 1.25rem;
}

.adminForm input[type='checkbox'] {
}

.adminForm input[type='checkbox']:hover {
  cursor: pointer;
}

.sessionJudges .table-responsive {
  overflow: visible;
}

.sessionJudges .table {
  padding: 20px 0;
}

.sessionJudges .table {
  cursor: default;
}

.sessionJudges .table-hover tbody tr:hover {
  background: inherit;
  cursor: default;
}

.sessionJudges .table td,
.sessionJudges .table th {
  vertical-align: middle;
}

.sessionJudges .sessionJudgeList {
  width: 100%;
}

.sessionJudges .sessionJudgeHeader {
  font-weight: bold;
}

.sessionJudges .sessionJudgeHeader div {
  padding: 0 3px;
}

.sessionJudges ul div {
  display: flex;
  align-items: center;
  line-break: anywhere;
  padding: 0 3px;
  color: #495057;
  white-space: nowrap;
}

.sessionJudges ul div::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.sessionJudges .judgeButtonContainer {
  height: 40px;
  padding: 0;
  justify-content: center;
}

.sessionJudges .judgeButton {
  border-radius: 50px;
  padding: 0;
  border: none;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sessionJudges .judgeButton:hover {
  background: none;
  color: #6c757d;
  border: 1px solid rgba(108, 117, 125, 0.5);
}

.sessionJudges .judgeButton:disabled {
  border: none;
  color: #495057;
  opacity: 1 !important;
}

.sessionJudges .judgeButton div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s;
  line-height: 1.5rem;
}

.sessionJudgeEmail {
  overflow-x: auto;
}

.sessionJudgeEmail::before {
  height: 100%;
  width: 20px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(10%, white),
    color-stop(90%, rgba(249, 249, 249, 0))
  );
  background: -webkit-linear-gradient(
    right,
    white 10%,
    rgba(249, 249, 249, 0) 90%
  );
  background: linear-gradient(to left, white 10%, rgba(249, 249, 249, 0) 90%);
  content: '';
  pointer-events: none;
  display: inline-block;
  position: absolute;
  right: -3px;
}

.sessionJudgeName .judgeTypeahead input {
  padding-right: 20px !important;
  border: none;
  background: none;
}

.sessionJudgeName .judgeTypeahead {
  padding: 0;
}

.sessionJudgeName .judgeTypeahead .rbt-menu {
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  /*transform: translate3d(0px, calc(-100%), 0px) !important;*/
  padding: 0.5rem 0rem;
  border-radius: 12px;
  background-clip: border-box;
  /*max-height: 288px !important;*/
  margin-top: 10px;
  margin-bottom: 10px;
}

.sessionJudgeName .judgeTypeahead .rbt-menu > .dropdown-item {
  padding: 0.25rem 10px;
}

.sessionJudgeName .judgeTypeahead button {
  font-size: 0.8rem;
  width: 32px;
  height: 32px;
}

.sessionJudgeName .editing input {
  box-shadow: 0 0.25rem 0.25rem -0.25rem rgb(0 123 255 / 70%);
}

.createOrJoinForm .img-thumbnail,
.adminForm .img-thumbnail {
  border: none;
  padding: 0;
  margin: 0 auto;
  /*max-height: 6rem;
  max-width: 6rem;*/
  max-width: 100%;
  max-height: 100%;
}

.team-select .img-thumbnail {
  max-height: 6rem;
  max-width: 6rem;
}

.loginForm label,
.createOrJoinForm label {
  width: 66%;
  margin: 0 auto;
  font-size: 0.75rem;
  color: #333;
}

.adminForm label {
  width: 100%;
  margin: 0;
  font-size: 0.75rem;
  color: #333;
}

.adminForm .custom-control-label {
  vertical-align: middle;
}

.adminForm .custom-control-label:hover {
  vertical-align: middle;
  cursor: pointer;
}

.adminForm .custom-checkbox .custom-control-label::before,
.adminForm .custom-checkbox .custom-control-label::after {
  top: 50%;
  translate: 0 -50%;
}

.loginForm .forgotPassword {
  border: none;
  background: none;
  width: 66%;
  margin: 0.5rem auto;
  text-align: left;
  padding: 0;
  font-size: 0.75rem;
  color: #007bff;
}

.loginForm .signUp,
.createOrJoinForm .linkButton,
.adminForm .linkButton,
.loginForm .linkButton {
  border: none;
  background: none;
  color: #007bff;
}

.loginForm .signUp:hover {
  text-decoration: underline;
}

.loginForm .forgotPassword:hover {
  text-decoration: underline;
}

.linkButton:hover {
  text-decoration: underline;
}

.loginSocial .row,
.loginForm .row,
.createOrJoinForm .row,
.adminForm .row {
  width: 100%;
  margin: 4px 0;
}

.adminForm .emptyList {
  min-height: 66vh;
  text-align: center;
}

.loginForm .newToVirtius,
.createOrJoinForm .modalLink,
.adminForm .modalLink,
.loginForm .modalLink {
  width: 50%;
  margin: 0 auto;
  justify-content: center;
  font-size: 0.75rem;
  max-width: 240px;
}

.loginSocial .googleButton,
.loginSocial .fbButton,
.loginForm .loginButton,
.createOrJoinForm .joinButton,
.createOrJoinForm .createButton,
.createOrJoinForm .cancelButton,
.adminForm .formButton,
.setupFooter .formButton,
.loginForm .verifyButton,
.sessionsFooter .formButton,
.scoreboardFooter .closeButton {
  height: 40px;
  width: 50%;
  min-width: 240px;
  margin: 0 auto;
  border-radius: 50px;
  max-width: 240px;
}

.loginSocial .social-logo {
  margin-right: 1rem;
  height: 100%;
}

.loginSocial .row .socialButtonRow {
  height: 100%;
  margin: 0;
}

.searchHeader {
  font-weight: 600;
  justify-content: left;
  font-size: 1.2rem;
  color: #333;
  position: relative;
}

.searchHeader span {
  padding-left: 2.4rem;
}

.loginHeader,
.createOrJoinHeader,
.setupHeader,
.adminHeader,
.sessionsHeader {
  font-weight: 600;
  justify-content: center !important;
  font-size: 1.2rem;
  color: #333;
  position: relative;
}

.createOrJoinForm .createSoloButton {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.2rem;
}

.createOrJoinForm .createDualButton {
  width: 160px;
  height: 80px;
  margin: 1rem auto;
  border-radius: 80px;
  font-weight: 600;
  font-size: 1.2rem;
}

.setupForm .streamButtonLG {
  height: 40px;
  margin: 1rem auto 0;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  border-radius: 50px;
}

.setupForm .streamButtonSM {
  height: 40px;
  margin: 1rem auto 0;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  border-radius: 50px;
  border: none;
}

.setupForm table tr:hover,
.adminForm table tr:hover,
.sessionsBody table tr:hover {
  cursor: pointer;
}

.setupFooter {
  padding: 0.75rem 0 !important;
}

.createOrJoinForm .sessionDesc,
.setupFooter .sessionDesc,
.adminForm .sessionDesc {
  font-size: 0.8rem;
  text-align: center;
  padding: 0 1rem;
  margin: 0;
}

.createOrJoinForm .dualButtonDesc {
  width: 160px;
  text-align: left;
  margin: 0 auto;
  padding: 0 2rem;
  font-size: 0.8rem;
}

.createOrJoinForm .soloButtonDesc {
  width: 80px;
  text-align: left;
  margin: 0 auto;
  padding: 0 0.7rem;
  font-size: 0.8rem;
}

.googleButton {
  background-color: #4285f4 !important;
}

.googleButton svg {
  background-color: white;
  padding: 2px;
  border-radius: 2px;
}

.googleButton:hover {
  background-color: #3f7ce8 !important;
  color: #eee;
}

.fbButton {
  background-color: #4267b2 !important;
}

.fbButton:hover {
  background-color: #2955ad !important;
  color: #eee;
}

#teamStripeLeft {
  left: 0;
  position: fixed;
  height: 100%;
  width: 2px;
  top: 0;
  background: #00a2ff;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);
}

#teamStripeRight {
  right: 0;
  position: fixed;
  height: 100%;
  width: 2px;
  top: 0;
  background: #00a2ff;
  box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.2);
}

.table thead th {
  border-top: none;
}

@media (max-width: 375px) {
  .loginHeader {
    font-size: 1rem;
  }
  .loginForm .alert,
  .createOrJoinForm .alert,
  .loginForm label,
  .createOrJoinForm label,
  .loginForm input,
  .createOrJoinForm input,
  .loginForm .password-container,
  .loginForm .passwordSpecs,
  .loginForm .codeSpecs {
    width: 80%;
  }
  .loginForm .newToVirtius,
  .createOrJoinForm .modalLink,
  .adminForm .modalLink,
  .loginForm .modalLink {
    min-width: 240px;
  }

  .loginModal {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .main,
  body,
  .footer {
    min-width: 320px;
  }

  .homeText {
    margin: 0 auto;
    width: 90%;
    overflow-wrap: break-word;
  }

  .teamBanner .logo {
    flex: 0 0 66%;
    max-width: 66%;
  }

  .teamBanner .logo img {
    max-height: 36px !important;
    min-height: 36px !important;
  }
  .lineups .left,
  .lineups .right {
    background: transparent !important;
  }
}

@media (max-width: 576px) {
  .main .container,
  .container-sm {
    max-width: 95%;
  }
  .heroGrid ul li {
    flex-wrap: wrap;
    /*    flex-direction: column-reverse;*/
  }
  .teamBanner .logo {
    flex: 0 0 66%;
    max-width: 66%;
  }
  .teamBanner .logo img {
    max-height: 36px !important;
    min-height: 36px !important;
    max-width: 55px !important;
  }
  .teamBanner .team,
  .roundSummary .team,
  .roundSummary .logo span {
    font-size: 1.2rem;
  }
  .lineups .left,
  .lineups .right {
    background: transparent !important;
  }
}

@media (min-width: 576px) {
  .main .container,
  .container-sm {
    max-width: 95%;
  }
  .heroGrid ul li {
    flex-wrap: wrap;
    /*    flex-direction: column-reverse;*/
  }
  .teamBanner .team,
  .roundSummary .team,
  .roundSummary .logo span {
    font-size: 1.4rem;
  }
  .lineups .left,
  .lineups .right {
    background: transparent !important;
  }
}

@media (min-width: 768px) {
  .main .container,
  .container-md,
  .container-sm {
    max-width: 95%;
  }
  .heroGrid .matchButton {
    max-width: 95%;
  }
  .heroGrid ul li {
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .teamBanner .team,
  .roundSummary .team,
  .roundSummary .logo span {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) {
  .main .container,
  .container-lg,
  .container-md,
  .container-sm {
    /*max-width: 960px;*/
    max-width: 95%;
  }
  .heroGrid .matchButton {
    max-width: 100%;
  }
  .teamBanner .team,
  .roundSummary .team,
  .roundSummary .logo span {
    font-size: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .main .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 1140px;
    width: 90%;
  }
}

@media (min-width: 1600px) {
  .main .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    /*max-width: 1140px;*/
    max-width: 90%;
  }
}

@media (min-width: 1920px) {
  .main .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1600px;
  }
}

@media (min-width: 2560px) {
  .main .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1920px;
  }
}

/*
@media only screen and (max-width: 640px) {
  .main,
  body,
  .footer {
    min-width: 375px;
  }
}
*/

/*
@media only screen and (max-width: 1024px) {
  html {
    font-size: 18px;
  }
}


@media only screen and (max-width: 768px) {
  html {
    font-size: 16px;
  }
}
*/

.heroLogo {
  width: 25%;
  min-width: 240px;
  margin: 0 auto;
  object-fit: contain;
  max-height: 100%;
}

.heroMessage {
  width: 100%;
  text-align: center;
  color: #ccc;
  font-size: 1rem;
  margin: 0.5rem auto;
}

.heroItalic {
  width: 100%;
  text-align: center;
  color: #ccc;
  font-size: 1rem;
  margin: 0rem auto;
}

.heroTagline {
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin: 0 auto;
  letter-spacing: -2.16px;
  font-family: Carmen Sans, sans-serif;
  font-weight: 700;
}

.heroMessage .dropdown button::after {
  display: none;
}

.heroGrid::before,
.heroGrid::after {
  /*content: '';*/
}

.heroGrid ul {
  margin: 0;
  padding: 0;
}

.heroGrid ul li {
  display: flex;
  width: 100%;
  border-top: 1px solid #eee;
  position: relative;
}

.heroGrid ul li button:hover,
.heroGrid ul li a:hover {
  /*text-decoration: none;*/
}

.heroGrid .live {
  color: #dc3545;
}

.heroGrid .past {
  color: gray;
}

.heroGrid .matchButton:hover:enabled {
  transform: scale(1.1);
}

.heroGrid .matchButton {
  transition: transform 0.3s;
  margin: 1rem;
}

.heroGrid .matchButton:disabled {
  opacity: 0.5 !important;
}

.heroGrid .matchButton:hover:disabled {
  transform: none;
}

.heroGrid ul li h5 {
  position: absolute;
  top: 0;
  font-size: 0.8rem;
  font-style: italic;
  color: #ccc;
  margin-top: 0.2rem;
  width: 100%;
  text-align: center;
}

.heroGrid {
  width: 100%;
  padding-bottom: 36px;
}

.footers {
  height: 8vh;
  margin-top: 6px;
  margin-bottom: 6px;
  min-height: 54px;
}

.left {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.center {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.right {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.swap {
  flex-direction: row-reverse;
}

.right .teamBanner .row,
.right .roundSummary .row,
.right .evaluator .row {
  flex-direction: row-reverse;
}

.right .teamBanner .team,
.right .roundSummary .team {
  text-align: right;
}

.roundSummary {
  height: 100%;
  overflow: hidden;
  font-size: 1.6rem;
  padding-left: 15px;
  padding-right: 15px;
}

.evaluator {
  height: 100%;
  font-size: 1.6rem;
  padding-left: 15px;
  padding-right: 15px;
}

.vCenter {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.competitorLine {
  min-height: 54px;
  height: 6vh;
  position: relative;
}

.teamBanner .score,
.competitorList .score,
.roundSummary .score {
  background: none;
  text-align: center;
  position: relative;
  font-variant-numeric: tabular-nums;
}

.left .competitorLine {
  box-shadow: -1px 0px 2px -2px rgba(0, 0, 0, 0.2);
}

.right .competitorLine {
  flex-direction: row-reverse;
  box-shadow: 1px 0px 2px -2px rgba(0, 0, 0, 0.2);
}

.teamBanner .team,
.roundSummary .team,
.roundSummary .logo span {
  /*text-transform: uppercase;*/
  /*font-variant: small-caps;*/
  font-feature-settings: normal;
  /*padding: 0.375rem 0.75rem !important;*/
}

.roundSummary .logo img {
  object-fit: contain;
  max-height: 64px;
  max-width: 75%;
  margin: 0 auto;
  min-height: 50px;
}

.roundSummary .icon {
  background: radial-gradient(rgba(0, 0, 0, 0.02) 5%, transparent 80%);
  height: 100%;
}

.roundSummary .icon svg {
  height: 66%;
}

.exportData,
.scoreboard,
.toggleTimer {
  height: 100%;
}

.exportData svg,
.scoreboard svg,
.toggleTimer svg {
  margin: 0 auto;
}

.exportData button,
.scoreboard button,
.toggleTimer button {
  border-radius: 100px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.2rem;
  display: flex;
  color: #5e5e5e;
  min-width: 36px;
  min-height: 36px;
}

.videoWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56.25%;
  /*border-radius: 12px;*/
  overflow: hidden;
}

.videoWrapper video {
  /*border-radius: 12px;*/
}

.videoWrapper video:focus,
.videoWrapper video:active {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.videoWrapper .playerModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*backdrop-filter: blur(5px) opacity(1);*/
  /*transition: backdrop-filter 1s, opacity 1s;j*/
  transition: opacity 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  /*border-radius: 12px;*/
}

.videoWrapper .playerModal:hover {
  /*backdrop-filter: blur(5px) opacity(0);*/
  opacity: 0;
}

.videoWrapper .playerModal .playerMessage {
  font-family: 'Product Sans';
  font-size: 2rem;
  text-align: center;
  user-select: none;
  text-shadow: 1px 1px 2px #333;
}

.videoWrapper .streamStatus {
  z-index: 1000;
  position: absolute;
  top: 0.4rem;
  font-size: 1rem;
  color: #aaa;
  left: 0.8rem;
}

.videoWrapper .streamSetup {
  z-index: 1000;
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
}

.videoWrapper .streamAlert {
  position: absolute;
  top: 0.4rem;
  right: 2.8rem;
  font-size: 0.8rem;
  background: rgba(0, 0, 0, 0.2);
  /*color: rgba(220, 53, 69, 1);*/
  color: #fff;
  height: 2rem;
  border-radius: 6px;
  user-select: none;
}

.videoWrapper .streamAlert button {
  background: transparent;
  border: none;
  padding: 0;
  font-size: 0.8rem;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  padding: 0.4rem 0.4rem;
  border-radius: 6px;
}

.videoWrapper .streamAlert button:active,
.videoWrapper .streamAlert button:focus {
  outline: 0;
  box-shadow: none !important;
}

.videoWrapper .videoDownload {
  z-index: 1000;
  position: absolute;
  top: 0.4rem;
  right: 5.2rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
}

.videoWrapper .frameCapture {
  z-index: 1000;
  position: absolute;
  top: 0.4rem;
  right: 2.8rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
}

.videoWrapper .streamMetrics {
  z-index: 1000;
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
}

.videoWrapper .streamMetrics .critical {
  background: rgba(220, 53, 69, 0.8) !important;
}

.videoWrapper .streamMetrics button,
.videoWrapper .streamSetup button,
.videoWrapper .frameCapture button,
.videoWrapper .videoDownload button {
  font-size: 0.75rem;
  color: #ccc;
  background: none;
  border: none;
  text-align: left;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
}

.videoWrapper .streamMetrics button svg,
.videoWrapper .streamSetup button svg,
.videoWrapper .frameCapture button svg,
.videoWrapper .videoDownload button svg {
  font-size: 1.5rem;
  color: #eee;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videoWrapper .streamMetrics button:hover,
.videoWrapper .streamSetup button:hover,
.videoWrapper .frameCapture button:hover,
.videoWrapper .videoDownload button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.videoWrapper .streamMetrics.max,
.videoWrapper .streamSetup.max,
.videoWrapper .frameCapture.max {
  max-width: 12rem;
  max-height: 12rem;
  width: auto;
  height: auto;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.8);
}

.videoWrapper .streamMetrics p,
.videoWrapper .streamSetup p,
.videoWrapper .frameCapture p {
  margin: 0;
}

.videoWrapper .streamSetupButton {
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videoWrapper .streamSetupButton button {
  border-radius: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: none;
  background: rgba(240, 240, 240, 0.6);
}

.videoWrapper .streamSetupButton button:hover {
  background: #ccc;
}

.videoWrapper .videoPlayer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  /*border-radius: 12px;*/
  overflow: hidden;
}

.videoWrapper .videoPlayer .video-js {
  background-color: #ffffff !important;
}

_:-webkit-full-screen:not(:root:root),
.QRcode {
  shape-rendering: auto;
}

.sessionInfo .tooltip-inner {
  max-width: initial;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background: #111;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.sessionInfo .arrow {
  display: none !important;
}

.sessionInfo .tooltip-inner p {
  margin: 0;
}

.sessionInfo .tooltip-inner .title {
  font-family: 'Product Sans';
}

.sessionInfo .tooltip-inner .date {
}

.tPanelRow {
  /*height: 100%;*/
  height: 70%;
  margin: 0 !important;
}

.tPanelDud {
  margin: 0 auto;
  display: flex;
}

.tPanelPlaceholder {
  height: 15%;
  min-width: 80%;
  width: 7.5vh;
  margin: 0 auto;
  vertical-align: middle;
  padding: 0.2rem;
}

.tPanelTopRow {
  height: 10%;
  margin: 0 auto !important;
  width: 80%;
  box-shadow: 0 0.25rem 0.25rem -0.25rem rgba(100, 100, 100, 0.15);
}

.tPanelTopRow span,
.tPanelBottomRow span {
  font-size: 0.9rem;
  margin: 0 auto;
  font-family: 'Product Sans';
  color: #666;
  transition: color 0.6s;
}

.tPanelMiddleRow {
  height: 80%;
  margin: 0 !important;
  padding: 20% 0;
}

.tPanelBottomRow {
  height: 10%;
  margin: 0 auto !important;
  width: 80%;
  box-shadow: 0 -0.25rem 0.25rem -0.25rem rgba(100, 100, 100, 0.15);
}

.tPanelTopRow button,
.tPanelMiddleRow button,
.tPanelBottomRow button {
  width: 7.5vh;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 6px;
  padding: 0.2rem;
  font-size: 0.9rem;
  background: #f8f8f8;
  border: none;
  min-width: 80%;
  height: 15%;
}

.tPanelTopRow button:hover,
.tPanelMiddleRow button:hover,
.tPanelBottomRow button:hover {
  background: #ccc;
}

.tPanelTopRow button:disabled,
.tPanelMiddleRow button:disabled,
.tPanelBottomRow button:disabled {
  background: none;
}

.tPanelCol {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.tPanelCol button {
  border-radius: 50%;
  margin: 4px auto;
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 0.8rem;
  background-color: #6c757d;
}

.sessionStreams ul li,
.sessionLineups ul li {
  font-size: 0.9rem;
  margin: 1rem 0;
  border: 1px solid rgba(230, 230, 230, 0.5);
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sessionStreams ul li .row,
.sessionLineups ul li .row {
  margin: 2px 0;
}

.sessionStreams ul li input,
.sessionLineups ul li input {
  border: none;
  height: auto;
  padding: 0 4px;
  font-size: 0.9rem;
  background: rgba(230, 230, 230, 0.75);
}

.sessionStreams ul li input:disabled,
.sessionLineups ul li input:disabled {
  border: none;
  height: auto;
  padding: 0;
  font-size: 0.9rem;
  background: rgba(250, 250, 250, 0.75);
}

.sessionStreams ul li .title,
.sessionLineups ul li .title {
  font-weight: 500;
}

.sessionStreams ul li .col,
.sessionLineups ul li .col {
  overflow-wrap: break-word;
}

.competitorList ul,
.scoreDeltas ul,
.sessionStreams ul,
.sessionJudges ul,
.sessionLineups ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.lineups hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.competitorList li,
.scoreDeltas li {
  /*margin-bottom: 6px;*/
  margin-bottom: 1vh;
  height: 6vh;
  min-height: 54px;
}

.hover {
  position: relative;
}
.hover:hover .hover__no-hover {
  opacity: 0;
}
.hover:hover .hover__hover {
  opacity: 1;
}
.hover__hover {
  position: absolute;
  top: 0;
  opacity: 0;
}
.hover__no-hover {
  opacity: 1;
}

.hidden {
  opacity: 0 !important;
}

.roundSummary,
.rDeltaRow {
  height: 8vh;
  min-height: 54px;
}

.rDeltaRow {
  border: 0;
}

.rDeltaCol {
  font-size: 1.2rem;
}

.deltaIndicator {
  width: 70%;
  height: 8px;
  bottom: 8px;
  position: absolute;
  left: 65%;
  margin-left: -50%;
}

.deltaBarLeft {
  height: 6px;
  position: absolute;
  border-right: 1px solid #fff;
  transition: width 0.6s;
  box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.2);
  /*border-radius: 0 4px 4px 0;*/
}

.deltaBarRight {
  height: 6px;
  position: absolute;
  border-left: 1px solid #fff;
  transition: width 0.6s;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);
  /*border-radius: 4px 0 0 4px; */
}

.deltaShaft {
  height: 2px;
  width: 100%;
  background: #ccc;
  border-radius: 1px;
}

.deltaTick {
  width: 2px;
  height: 100%;
  background: #ccc;
  border-radius: 1px;
  position: absolute;
  left: 50%;
  margin-left: -1px;
}

.scoreDeltas li {
  position: relative;
  min-height: 54px;
  font-size: 1rem;
}

.toastStack {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 1rem));
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.toastStack::-webkit-scrollbar,
.toastStack .toast::-webkit-scrollbar,
.toastStack .toast-header::-webkit-scrollbar,
.toastStack .toast-header span::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.toastStack .toast,
.toastStack .toast-header {
  border-radius: 12px;
}

.toastStack .toast-header {
  border-bottom: none;
}

.toastStack .error .toast-header {
  background: #dc3545;
  color: #fff;
}

.toastStack .error .toast-header .close {
  color: #fff;
}

.toastStack .success .toast-header {
  background: #28a745;
  color: #fff;
}

.toastStack .toast-header span {
  white-space: nowrap;
  /*text-overflow: ellipsis;*/
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.evaluator .finalize:hover {
  background: none;
  /*color: #fff;*/
}

.evaluator .finalize:not(:disabled):not(.disabled).active:focus,
.evaluator .finalize:not(:disabled):not(.disabled):active:focus,
.show > .evaluator .finalize .dropdown-toggle:focus {
  background: none !important;
  box-shadow: none;
}

.competitorList .scorecard:not(:disabled):not(.disabled).active:focus,
.competitorList .scorecard:not(:disabled):not(.disabled):active:focus,
.show > .competitorList .scorecard .dropdown-toggle:focus {
  background: none !important;
  box-shadow: none;
}

.evaluator .finalize :active :focus,
.competitorList .scorecard :active :focus {
  background: none !important;
  color: #fff !important;
  box-shadow: none;
}

.evaluator .finalize:focus,
.competitorList .scorecard:focus {
  box-shadow: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5 !important;
}

.blinking {
  -webkit-animation: glowing 3000ms ease infinite;
  -moz-animation: glowing 3000ms ease infinite;
  -o-animation: glowing 3000ms ease infinite;
  animation: glowing 3000ms ease infinite;
  /*background: transparent;*/
  /*background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));*/
  background: radial-gradient(transparent, transparent);
}

@-webkit-keyframes glowing {
  0% {
    background-color: none;
    -webkit-box-shadow: 0;
  }
  50% {
    background-color: rgba(25, 25, 25, 0.7);
    -webkit-box-shadow: 0 0 5px rgba(25, 25, 25, 0.7);
  }
  100% {
    background-color: none;
    -webkit-box-shadow: 0;
  }
}

@keyframes glowing {
  0% {
    background-color: none;
    box-shadow: 0;
  }
  50% {
    background-color: rgba(25, 25, 25, 0.7);
    box-shadow: 0 0 5px rgba(25, 25, 25, 0.7);
  }
  100% {
    background-color: none;
    box-shadow: 0;
  }
}

.blinking:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  background: #6c757d;
}

.blinkingYellow {
  -webkit-animation: glowingYellow 3000ms ease infinite;
  -moz-animation: glowingYellow 3000ms ease infinite;
  -o-animation: glowingYellow 3000ms ease infinite;
  animation: glowingYellow 3000ms ease infinite;
  /*background: transparent;*/
  /*background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));*/
  background: radial-gradient(transparent, transparent);
  background-color: rgba(255, 193, 7, 0.5);
  transition: none !important;
}

.blinkingWarning {
  -webkit-animation: glowingWarning 3000ms ease infinite;
  -moz-animation: glowingWarning 3000ms ease infinite;
  -o-animation: glowingWarning 3000ms ease infinite;
  animation: glowingWarning 3000ms ease infinite;
  background: radial-gradient(transparent, transparent);
}

@-webkit-keyframes glowingWarning {
  0% {
    -webkit-box-shadow: 0;
  }
  50% {
    -webkit-box-shadow: 0 0 5px rgba(255, 193, 7, 0.7);
  }
  100% {
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingWarning {
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 5px rgba(255, 193, 7, 0.7);
  }
  100% {
    box-shadow: 0;
  }
}

@-webkit-keyframes glowingYellow {
  0% {
    background-color: rgba(255, 193, 7, 1);
    -webkit-box-shadow: 0;
  }
  50% {
    background-color: rgba(255, 193, 7, 0.7);
    -webkit-box-shadow: 0 0 5px rgba(255, 193, 7, 0.7);
  }
  100% {
    background-color: rgba(255, 193, 7, 1);
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingYellow {
  0% {
    background-color: rgba(255, 193, 7, 1);
    box-shadow: 0;
  }
  50% {
    background-color: rgba(255, 193, 7, 0.7);
    box-shadow: 0 0 5px rgba(255, 193, 7, 0.7);
  }
  100% {
    background-color: rgba(255, 193, 7, 1);
    box-shadow: 0;
  }
}

.blinkingYellow:hover,
.blinkingWarning:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.blinkingGreen {
  -webkit-animation: glowingGreen 3000ms ease infinite;
  -moz-animation: glowingGreen 3000ms ease infinite;
  -o-animation: glowingGreen 3000ms ease infinite;
  animation: glowingGreen 3000ms ease infinite;
  /*background: transparent;*/
  /*background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));*/
  background: radial-gradient(transparent, transparent);
  background-color: rgba(40, 167, 69, 0.5);
  transition: none !important;
}

@-webkit-keyframes glowingGreen {
  0% {
    background-color: rgba(40, 167, 69, 1);
    -webkit-box-shadow: 0;
  }
  50% {
    background-color: rgba(40, 167, 69, 0.7);
    -webkit-box-shadow: 0 0 5px rgba(40, 167, 69, 0.7);
  }
  100% {
    background-color: rgba(40, 167, 69, 1);
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingGreen {
  0% {
    background-color: rgba(40, 167, 69, 1);
    box-shadow: 0;
  }
  50% {
    background-color: rgba(40, 167, 69, 0.7);
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.7);
  }
  100% {
    background-color: rgba(40, 167, 69, 1);
    box-shadow: 0;
  }
}

.blinkingGreen:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.blinkingLive {
  -webkit-animation: glowingLive 3000ms ease infinite;
  -moz-animation: glowingLive 3000ms ease infinite;
  -o-animation: glowingLive 3000ms ease infinite;
  animation: glowingLive 3000ms ease infinite;
  background: radial-gradient(transparent, transparent);
}

@-webkit-keyframes glowingLive {
  0% {
    -webkit-box-shadow: 0;
  }
  50% {
    -webkit-box-shadow: 0 0 5px rgba(220, 53, 69, 0.7);
  }
  100% {
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingLive {
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.7);
  }
  100% {
    box-shadow: 0;
  }
}

.blinkingLive:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  /*background: #6c757d !important;
  border-color: #6c757d !important;*/
}

/*

.blinkingLive:focus {
  box-shadow: 0 0 0 0.2rem rgba(108,117,125,.5) !important;
}

.notLive:hover {
  background: #dc3545 !important;
  border-color: #dc3545 !important;
}

.notLive:focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.5) !important;
}
*/

.blinkingEdit {
  -webkit-animation: glowingEdit 3000ms ease infinite;
  -moz-animation: glowingEdit 3000ms ease infinite;
  -o-animation: glowingEdit 3000ms ease infinite;
  animation: glowingEdit 3000ms ease infinite;
  background: radial-gradient(transparent, transparent);
}

@-webkit-keyframes glowingEdit {
  0% {
    -webkit-box-shadow: 0;
  }
  50% {
    -webkit-box-shadow: 0 0 5px rgba(0, 123, 255, 0.7);
  }
  100% {
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingEdit {
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.7);
  }
  100% {
    box-shadow: 0;
  }
}

.blinkingEdit:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.blinkingClip {
  -webkit-animation: glowingClip 3000ms ease infinite;
  -moz-animation: glowingClip 3000ms ease infinite;
  -o-animation: glowingClip 3000ms ease infinite;
  animation: glowingClip 3000ms ease infinite;
  background: radial-gradient(transparent, transparent);
}

@-webkit-keyframes glowingClip {
  0% {
    -webkit-box-shadow: 0;
  }
  50% {
    -webkit-box-shadow: 0 0 5px rgba(40, 167, 69, 0.7);
  }
  100% {
    -webkit-box-shadow: 0;
  }
}

@keyframes glowingClip {
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.7);
  }
  100% {
    box-shadow: 0;
  }
}

.blinkingClip:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.blinkingLiveText {
  color: rgba(220, 53, 69, 1) !important;
}

.green {
  color: #28a745;
}

.red {
  color: #dc3545;
}

.yellow {
  color: #ffe601;
}

#popover-finalize,
#popover-scorecard {
  width: 375px;
  min-width: 375px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-left: -5px !important;
  font-variant-numeric: tabular-nums;
}

[data-theme='dark'] #popover-scorecard {
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.15);
  background-color: var(--background);
  border-radius: 3px;
  border: 1px solid var(--text-primary-hover);
}

[data-theme='dark'] #popover-scorecard .popover-header {
  background-color: var(--background-haze);
  color: var(--text-primary);
  border-top-right-radius: calc(0.3rem - 1px);
  border-top-left-radius: calc(0.3rem - 1px);
}

[data-theme='dark'] #popover-scorecard .popover-body {
  background-color: var(--background);
  color: var(--text-primary);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

#popover-eval {
  width: 300px;
  min-width: 300px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

#popover-eval .popover-body .row,
#popover-finalize .popover-body .row,
#popover-scorecard .popover-body .row {
  padding: 4px 0;
}

#popover-finalize .score,
#popover-scorecard .score {
  font-weight: 600;
  /*  font-size: 1.4rem;*/
  font-size: 1.2rem;
}

#popover-finalize .popover-body,
#popover-scorecard .popover-body {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}

#popover-eval .popover-body {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

#popover-eval .popover-header,
#popover-finalize .popover-header,
#popover-scorecard .popover-header {
  /*text-align: center;*/
  /*font-size: 1.6rem;*/
  font-size: 1.2rem;
}

#popover-eval .popover-body .numButton {
  height: 72px;
  width: 72px;
  margin: 0 8px;
  font-size: 2rem;
  font-weight: 300;
  border-radius: 100px;
}

#popover-eval .popover-body button {
  border-radius: 6px;
  padding: 0;
  height: 48px;
  width: 48px;
  display: flex;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 600;
}

#popover-finalize .popover-body button {
  display: flex;
  margin: 0 auto;
  padding: 0.375rem 0.75rem;
  border-radius: 8px;
}

#popover-scorecard .popover-body button {
  display: flex;
  margin: 0 auto;
  padding: 0.375rem 0.75rem;
  border-radius: 8px;
  min-width: 60px;
  font-size: 1rem;
}

#popover-eval .popover-body .valid {
  /*color: #28a745;
  border-color: #28a745;*/
}

#popover-eval .popover-body svg,
#popover-finalize .popover-body svg,
#popover-scorecard .popover-body svg {
  margin: 0 auto;
}

#popover-eval .popover-body input {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

input:focus::placeholder {
  color: transparent;
}

.inquiryIndicator {
  /*color: #ffc107; */
  position: absolute;
  /*  top: 6px;*/
  top: 0.5vh;
  display: flex;
  font-size: 1.2rem;
}

.left .inquiryIndicator,
.right .stickIndicator,
.right .penaltyIndicator,
.left .dScoreIndicator {
  left: 0px;
}

.right .inquiryIndicator,
.left .stickIndicator,
.left .penaltyIndicator,
.right .dScoreIndicator {
  right: 0px;
}

.stickIndicator {
  position: absolute;
  /*  bottom: 10px;*/
  bottom: 0.5vh;
  display: flex;
  font-size: 1.2rem;
  width: 0.8rem;
  right: calc(-1vw) !important;
}

.penaltyIndicator {
  position: absolute;
  /*  top: 10px;*/
  /*  top: 0.25vh;*/
  bottom: 0.5vh;
  display: flex;
  font-size: 1.2rem;
  width: 0.8rem;
  right: calc(-1vw + 1em) !important;
}

.dScoreIndicator {
  position: absolute;
  /*  bottom: 6px;*/
  bottom: 0.5vh;
  display: flex;
  font-size: 0.8rem;
}

.inquiryHeader {
  text-align: center;
  font-style: italic;
  font-weight: 600;
}

.unresolved {
  color: #ffc107;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--header-gradient);
  min-height: 72px;
  z-index: 1002;
}

.headers {
  height: 72px;
  top: 0;
  z-index: 100;
}

.headers .center {
  background-clip: border-box;
  background: var(--background);
}

.headers .left,
.headers .right {
  background-clip: border-box;
}

.headers .left {
  background: var(--header-gradient-left);
}

.headers .right {
  background: var(--header-gradient-right);
}

.sessionFooterHidden {
  transform: translateY(85%);
}

.sessionFooter {
  background-color: var(--background-haze);
  position: fixed;
  z-index: 900;
  width: 100%;
  left: 0;
  bottom: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}

.sessionFooter .footerToggle {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  color: #5e5e5e;
  border: none;
  font-size: 1.5rem;
  padding: 0;
  /*  height: 1rem;*/
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  display: flex;
  z-index: 11;
}

.sessionFooter .footerToggle svg {
  margin: 0 auto;
}

.linkVideoPreviewModal .modal-content {
  border-radius: 25px;
}

.linkVideoPreviewModal .videoWrapper .videoPlayer {
  background: #f8f8f8;
}

.linkVideoPreviewFooter .closeButton,
.linkVideoPreviewFooter .linkButton {
  height: 40px;
  width: 50%;
  min-width: 240px;
  margin: 0 auto;
  border-radius: 50px;
}

.linkVideoPreviewHeader {
  font-weight: 600;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #333;
  position: relative;
}

.linkVideoPreviewHeader span {
  /*padding-left: 2.4rem;*/
}

.linkVideoPreviewHeader .headerName {
  float: right;
  font-weight: 400;
  font-style: italic;
  color: #666;
}

.linkVideoPreviewFooter {
  position: relative;
}

.linkVideoPreviewFooter .progress {
  position: absolute;
  top: 0;
  margin: 0;
  left: 0;
  height: 2%;
  width: 100%;
  background-color: transparent;
}

.linkVideoPreviewModal .linkInput {
  margin: 1rem 0 0 0;
}

.linkVideoPreviewModal .linkInput input {
  border-radius: 0.25rem !important;
  margin-right: 1rem;
}

.linkVideoPreviewModal .linkInput input::placeholder {
  color: #d3d3d3;
}

.linkVideoPreviewModal .linkInput button {
  min-width: 10%;
}

.linkVideoPreviewModal .noYTVideo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
  z-index: 1000;
  display: block;
  font-size: 3rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: 100%;
  text-align: center;
}

.linkVideoPreviewModal .noYTVideo svg {
  height: 8rem;
  width: 8rem;
  color: #ccc;
}

.genderSelect {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  gap: 0.75rem;
}

.genderSelect button {
  font-weight: 600;
  min-width: 100px;
  /* background-color: white; */
  border: 1px solid #dee2e6;
  padding: 2px 0px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50rem;
}

.genderSelect button:hover {
  background-color: #f8f9fa;
}

.genderSelect button[aria-pressed='true'] {
  color: #ffffff;
  font-weight: 600;
}

.genderSelect button[aria-pressed='true'][value='MALE'] {
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 2px 4px rgba(108, 117, 125, 0.25);
}

.genderSelect button[aria-pressed='true'][value='FEMALE'] {
  background-color: #d63384;
  border-color: #d63384;
  box-shadow: 0 2px 4px rgba(214, 51, 132, 0.25);
}

.genderSelect button[aria-pressed='true'][value='COED'] {
  background-color: #6610f2;
  border-color: #6610f2;
  box-shadow: 0 2px 4px rgba(102, 16, 242, 0.25);
}

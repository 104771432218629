@media print {
  .noprint {
    /* visibility: hidden;*/
    display: none !important;
  }

  .scoreSlipSection .scoreSlip {
    margin-top: 0rem !important;
    margin-bottom: 2rem !important;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  section {
    margin: 0 !important;
  }
}

.card .judgeSignature {
  font-size: 1rem;
  padding: 0.1rem 0.25rem 0.1rem 0.25rem;
  border-top: none;
  background: none;
  text-align: right;
}

.scoreSlipSection {
  page-break-before: always;
  break-before: always;
}

.scoreSlip h2 {
  min-height: 2.5rem;
}

.scoreSheetBlankButton,
.scoreSheetPrintButton {
  position: absolute;
  top: 50%;
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
  transform: translate(0, -50%);
}

.scoreSheetBlankButton {
  right: 6.5rem;
}

.scoreSheetPrintButton {
  right: 4rem;
}

.scoreSheetBlankButton button,
.scoreSheetPrintButton button {
  font-size: 0.75rem;
  color: #ccc;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  text-align: left;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
}

.scoreSheetBlankButton button:disabled,
.scoreSheetPrintButton button:disabled {
  background-color: rgba(0, 0, 0, 0.4);
}

.scoreSheetBlankButton button svg,
.scoreSheetPrintButton button svg {
  font-size: 1.5rem;
  color: #eee;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scoreSheetBlankButton button:hover,
.scoreSheetPrintButton button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.scoreSheetBlankButton button:hover:disabled,
.scoreSheetPrintButton button:hover:disabled {
  background: rgba(0, 0, 0, 0.4);
}

/* Safari-specific print layout fixes */
@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .scoreSlipSection {
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: space-between !important;
      page-break-before: always !important;
    }

    .scoreSlipSection .scoreSlip {
      width: 48% !important;
      flex: 0 0 48% !important;
      margin: 0 0 2rem 0 !important;
      page-break-inside: avoid !important;
      break-inside: avoid !important;
    }

    .scoreSlipSection .row {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      display: contents !important;
    }

    /* Force 6 cards per page in Safari */
    @page {
      margin: 0.5cm;
    }
  }
}

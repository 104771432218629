.evaluatorSlab {
  width: 66%;
}

/*.evaluatorSlab .row {
  height: 100%;
}*/

.evaluatorSlab .evaluatorSlabPanel {
  /*  border: 1px solid #333;*/
  width: 50%;
}

.evaluatorSlab .evaluatorSlabPanel .slabAthlete,
.evaluatorSlab .evaluatorSlabPanel .finalScore,
.evaluatorSlab .evaluatorSlabInput .inputType {
  width: 100%;
  margin: 0 0 2rem 0;
  font-size: 2rem;
  font-weight: 500;
}

.evaluatorSlab .evaluatorSlabPanel .finalScore {
  /*  border: 1px solid #ccc;*/
  /*  border-radius: 0.25rem;*/
  text-align: right;
  width: 100%;
  height: 5rem;
  position: relative;
  padding-right: 1rem;
}

.evaluatorSlab .evaluatorSlabPanel .finalScore .superScript {
  position: absolute;
  left: 1rem;
  top: -0.8rem;
  font-size: 1rem;
  background: var(--background);
  padding: 0rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--background);
}

.evaluatorSlab .evaluatorSlabPanel .finalScore .selected .superScript {
  border: 1px solid #ffc107;
}

.evaluatorSlab .evaluatorSlabInput .inputLead {
  position: absolute;
  height: 100%;
  font-size: 2.4rem;
  font-weight: 400;
  margin: 1.5rem;
  color: #999;
}

.evaluatorSlab .evaluatorSlabPanel .row,
.evaluatorSlab .evaluatorSlabInput h3 {
  height: auto;
}

.evaluatorSlab .evaluatorSlabInput {
  /*  border: 1px solid #333;*/
  width: 50%;
  /*  background: #e8e8e8;*/
  margin-bottom: 1vh;
  padding: 0;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel,
.evaluatorSlab .evaluatorSlabPanel .evalExtras {
  flex-direction: column;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel .row,
.evaluatorSlab .evaluatorSlabPanel .evalExtras .row {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
  width: 100%;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel,
.evaluatorSlab .evaluatorSlabPanel .evalExtras {
  font-variant-numeric: tabular-nums;
  font-size: 1.6rem;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel .scoreLabel,
.evaluatorSlab .evaluatorSlabPanel .evalExtras .scoreLabel {
  padding-left: 0;
  padding-right: 0;
  padding: 0.375rem 0;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel .scoreButton,
.evaluatorSlab .evaluatorSlabPanel .evalExtras .scoreButton {
  padding-left: 0;
  padding-right: 1rem;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel button,
.evaluatorSlab .evaluatorSlabPanel .evalExtras button,
.evaluatorSlab .evaluatorSlabPanel .finalScore button {
  font-size: 1.6rem;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  text-align: right;
  font-variant-numeric: tabular-nums;
  min-height: 3.4rem;
}

.evaluatorSlab .evaluatorSlabPanel .finalScore button {
  font-size: 2.4rem;
}

.evaluatorSlab .evaluatorSlabPanel .evalPanel .selected,
.evaluatorSlab .evaluatorSlabPanel .evalExtras .selected,
.evaluatorSlab .evaluatorSlabPanel .finalScore .selected {
  border: 1px solid #ffc107;
  background-color: rgba(255, 193, 7, 0.2);
}

.evaluatorSlab .evaluatorSlabInput .numberPad {
  margin: 1rem 0;
}

.evaluatorSlab .evaluatorSlabInput .submitLine {
  margin: 1rem 0;
}

.evaluatorSlab .evaluatorSlabInput .scoreLine {
  margin: 1rem 0;
}

.evaluatorSlab .evaluatorSlabInput input {
  text-align: right;
  padding-right: 2rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  font-size: 2.4rem;
  font-weight: 600;
  padding: 0 1.5rem;
  height: 5rem;
  font-variant-numeric: tabular-nums;
}

.evaluatorSlab .evaluatorSlabInput input:disabled {
  /*  border: 1px solid #CCC;*/
  background: #eee;
}

.evaluatorSlab .evaluatorSlabInput .submitCheck,
.evaluatorSlab .evaluatorSlabInput .valid,
.evaluatorSlab .evaluatorSlabInput .submit {
  height: 100%;
  font-size: 2rem;
  display: flex;
}

.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group button,
.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group .clear,
.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group .save {
  margin: 0.5rem;
  border-radius: 0.25rem;
}

.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group .clear {
  width: 33%;
}

.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group .save {
  width: 66%;
}

.evaluatorSlab .evaluatorSlabInput .submitLine .col {
  margin: -0.5rem 0;
}

.evaluatorSlab .evaluatorSlabInput .submitLine .btn-group {
  margin: 0 -0.5rem;
}

.evaluatorSlab .evaluatorSlabInput .submit svg,
.evaluatorSlab .evaluatorSlabInput .valid svg {
  width: 100%;
}

.evaluatorSlab
  .evaluatorSlabInput
  input[type='number']::-webkit-outer-spin-button,
.evaluatorSlab
  .evaluatorSlabInput
  input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
.evaluatorSlab .evaluatorSlabInput input[type='number'] {
  -moz-appearance: textfield;
}

.evaluatorSlab .evaluatorSlabInput .numberPad .numberRow {
  margin: 0rem 0;
}

.evaluatorSlab .evaluatorSlabInput .numberPad .numberRow .numberGroup {
  height: 5rem;
  margin: 0 -0.5rem;
}

.evaluatorSlab .evaluatorSlabInput .numberPad .numberRow .numberGroup button {
  /*  padding: 0.5rem;*/
  margin: 0.5rem;
  border-radius: 0.25rem;
  width: 33%;
  display: flex;
}

.evaluatorSlab .evaluatorSlabInput .submitLine {
  height: 5rem;
}

.evaluatorSlab .evaluatorSlabInput .submitLine button {
  height: 5rem;
  width: 100%;
  text-align: center;
}

.evaluatorSlab
  .evaluatorSlabInput
  .numberPad
  .numberRow
  .numberGroup
  button:focus,
.evaluatorSlab
  .evaluatorSlabInput
  .numberPad
  .numberRow
  .numberGroup
  button:active {
  background-color: #f8f9fa;
}

.evaluatorSlab
  .evaluatorSlabInput
  .numberPad
  .numberRow
  .numberGroup
  button
  span,
.evaluatorSlab
  .evaluatorSlabInput
  .numberPad
  .numberRow
  .numberGroup
  button
  svg,
.evaluatorSlab .evaluatorSlabInput .submitLine button span {
  font-size: 2rem;
  font-weight: 600;
  width: 100%; /* Span takes the full width of the button */
  text-align: center;
}

.evaluatorSlab .evaluatorSlabInput .evaluatorSlabInputSlab {
  background: #e8e8e8;
  padding: 15px;
}

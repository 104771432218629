.competitorList .uploadVideoButton,
.competitorList .linkVideoButton {
  border-radius: 0;
  padding: 0 1rem;
  width: 100%;
  border-color: transparent;
  height: 27px;
}

.competitorList .uploadVideoDrop,
.competitorList .linkVideoContainer {
  width: 100%;
  position: relative;
}

.left .competitorList .hasClip {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: #28a745;
}

.right .competitorList .hasClip {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: #28a745;
}

.teamFilterA {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50%;
  z-index: 1000;
}

.teamFilterB {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 50%;
  z-index: 1000;
}

.teamFilterA input,
.teamFilterB input,
.teamFilterA .editing .rbt-input,
.teamFilterB .editing .rbt-input,
#search-teams-input {
  border: none;
  background: none;
  border-bottom: 1px solid #00a2ff;
  border-radius: 0px;
  color: #333;
  font-weight: 300;
  font-size: 1.5rem;
}

.teamFilterA input:focus::placeholder,
.teamFilterB input:focus::placeholder,
.teamFilterA .editing .rbt-input:focus::placeholder,
.teamFilterB .editing .rbt-input:focus::placeholder,
#search-teams-input:focus::placeholder {
  color: #aaa;
}

.teamFilterA input:focus,
.teamFilterB input:focus,
.teamFilterA .editing .rbt-input:focus,
.teamFilterB .editing .rbt-input:focus,
#search-teams-input:focus {
  outline: none;
}

.createOrJoinForm .teamSelector {
  background: rgba(255, 255, 255, 0.9);
}

.createOrJoinForm .teamSelector .row {
  min-height: 100%;
}

.teamBannerLarge {
  display: flex;
  font-weight: 600;
  font-size: 3rem;
  width: 100%;
  flex-direction: row;
  font-family: Carmen Sans, sans-serif;
  font-variant-numeric: tabular-nums;
}

.teamBannerLarge .scoreboardHeaderLogo {
  height: 4rem;
  padding: 0.5rem;
  width: 4rem;
  object-fit: contain;
}

.teamBannerLarge ul li {
  white-space: nowrap;
  padding: 0 1rem;
  display: inline-block;
  vertical-align: middle;
  float: left;
  padding: 1rem;
}

.teamBannerCompact {
  height: 100%;
  direction: ltr !important;
  font-variant-numeric: tabular-nums;
}

.teamBannerCompact .scoreContainer {
  justify-content: end;
  flex-direction: row;
  align-items: center;
}

.teamBannerCompact .scoreboardHeaderLogo {
  height: 1.6rem;
  width: 1.6rem;
  object-fit: contain;
  padding: 0;
}

.teamBannerCompact ul,
.teamBannerLarge ul {
  list-style-type: none;
  margin: 0; /* To remove default bottom margin */
  padding: 0; /* To remove default left padding */
}

.teamBannerCompact ul li {
  white-space: nowrap;
  padding: 0 1rem;
  display: inline-block;
  vertical-align: middle;
  float: left;
  padding: 0 0.25rem;
  position: relative;
}

.teamBannerCompact .scorecard {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-variant-numeric: tabular-nums;
}

.teamBannerCompact .scorecard .inquiryIndicator {
  bottom: -8px;
  right: -10px;
  left: unset;
}

.teamBannerCompact .scorecard .penaltyIndicator {
  top: 0;
  right: -8px;
}

#teamScoreCardButton {
  width: 100%;
  justify-content: center;
}

.teamBannerCompact span {
  font-size: 1.4rem;
}

.teamBannerCompact .score {
  direction: ltr;
}

.teamBannerCompact img {
  /*  max-height: 48px;*/
  max-height: 6rem;
  max-width: 100%;
}

.teamBanner {
  height: 100%;
  font-size: 1.6rem;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1;
}

.teamBanner .logo,
.right .teamBanner,
.left .teamBanner {
  background-color: var(--background-haze);
}

/*[data-theme='dark'] .teamBanner .logo img,*/
[data-theme='dark'] .teamBanner .teamBannerSelector .dropdown-item img {
  filter: drop-shadow(0 0 0.3rem #fff);
}

.teamBanner .logo svg {
  max-width: 6vh;
  max-height: 6vh;
  margin: 0 auto;
}

.right .teamBanner .team {
  text-align: right;
}

.left .teamBanner .team {
  text-align: left;
}

.left .teamBanner .score {
  background: var(--header-gradient-left);
  direction: rtl;
}

.right .teamBanner .score {
  background: var(--header-gradient-right);
}

.teamBanner .teamBannerSelector {
  height: 100%;
}

.teamBanner .teamBannerSelector .teamBannerSelector-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid black;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  background: var(--background);
  border-radius: 0 0 12px 12px;
  top: auto !important;
  transform: translate(0, 0) !important;
}

.teamBanner .teamBannerSelector .dropdown-item {
  padding: 0.25rem 1rem;
}

.teamBanner .teamBannerSelector .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  width: 100%;
  padding: 0;
  color: var(--text-primary);
}

.left .teamBanner .teamBannerSelector .dropdown-toggle::after {
  position: absolute;
  bottom: 8%;
  left: 25%;
  margin-left: 0;
}

.right .teamBanner .teamBannerSelector .dropdown-toggle::after {
  position: absolute;
  bottom: 8%;
  right: 25%;
}

.scoreboardView .teamBanner .teamBannerSelector .dropdown-toggle::after {
  display: none;
}

.teamBanner .teamBannerSelector ul {
  list-style-type: none;
  margin: 0; /* To remove default bottom margin */
  padding: 0; /* To remove default left padding */
}

.teamBanner .teamBannerSelector ul li button {
  background-color: var(--background);
  color: #aaa;
  border: none;
  width: 100%;
  display: flex;
  border-radius: 0;
}

.right .teamBanner .teamBannerSelector ul li button {
  flex-direction: row-reverse;
}

.teamBanner .teamBannerSelector ul li button:hover {
  color: #888;
}

.teamBanner .teamBannerSelector .dropdown-toggle,
.teamBanner .teamBannerSelector ul li button:focus,
.teamBanner .teamBannerSelector ul li button:active {
  outline: 0;
  box-shadow: none !important;
}

.teamBanner .teamBannerSelector .selected {
  color: #111;
}

[data-theme='dark'] .teamBanner .teamBannerSelector .selected {
  color: #fff;
}

.teamBanner .teamBannerSelector ul li button div {
  white-space: nowrap;
  padding: 0 1vw;
  display: inline-block;
  vertical-align: middle;
  line-height: 48px;
}

.teamBanner .teamBannerSelector img {
  /*  max-height: 48px;*/
  max-height: 6rem;
  max-width: 100%;
}

.teamBanner .logo img {
  object-fit: contain;
  max-height: 64px;
  max-width: 75%;
  margin: 0 auto;
  min-height: 50px;
}

.teamBanner .score {
  max-height: 72px;
}

.teamBanner .score svg {
  max-height: 65%;
  max-width: 85%;
  object-fit: contain;
  margin: auto;
}

[font-theme='800'] .teamBanner .team,
[font-theme='800'] .teamBanner .score,
[font-theme='700'] .teamBanner .team,
[font-theme='700'] .teamBanner .score,
[font-theme='600'] .teamBanner .team,
[font-theme='600'] .teamBanner .score {
  font-weight: var(--font-weight);
  /*font-size: 1.8rem;*/
  /*font-size: 3.2vh;*/
  font-size: 2.8vw;
}

@media (min-width: 768px) {
  .teamBannerCompact .scorecard {
    font-size: 1.4rem;
    padding: 0;
    line-height: 1.4rem;
  }
}

@media (min-width: 576px) {
  .teamBannerCompact .scorecard {
    font-size: 1.4rem;
    padding: 0;
    line-height: 1.4rem;
  }
  .teamBannerCompact .scoreboardHeaderLogo {
    margin: 0 0.5rem;
  }
}

@media (max-width: 576px) {
  .teamBannerCompact span {
    font-size: 0.8rem;
  }
  .teamBannerCompact {
    /*    line-height: 0.75rem;*/
  }
  .teamBannerCompact .scoreboardHeaderLogo {
    height: 1.2rem;
    width: 1.2rem;
    object-fit: contain;
    margin: 0;
  }
  /*  .teamBanner .logo {
    max-width: 60%;
    flex: 0 0 60%;
  }*/
  .experimental .teamBanner .score {
    max-width: 62%;
    flex: 0 0 62%;
  }
  .teamBanner .logoContainer .logo {
    margin-left: 1.5rem;
  }
  .teamBanner .logoContainer {
    max-width: 38%;
  }
  .teamBannerCompact .scorecard {
    font-size: 1rem;
    padding: 0;
    line-height: 1rem;
  }
}

@media (max-width: 375px) {
  .teamBannerCompact span {
    font-size: 0.8rem;
  }
  .teamBannerCompact {
    line-height: 0.6rem;
  }
  .teamBannerCompact .scoreboardHeaderLogo {
    height: 1.2rem;
    width: 1.2rem;
    object-fit: contain;
    margin: 0;
  }
  /*  .teamBanner .logo {
    max-width: 40%;
    flex: 0 0 40%;
  }*/
  .experimental .teamBanner .score {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .teamBannerCompact .scorecard {
    font-size: 0.8rem;
    padding: 0;
    line-height: 0.8rem;
  }
}

/*body {
  line-height: 5pt;
}*/

@page {
  size: 8.5in 11in;
}

.main {
  /*display: block;
  padding: 2rem;
  min-width: 670px !important;
  size: letter;
*/
  width: 8.5in;
  /*  height: 11in;*/
  min-width: 8.5in;
  aspect-ratio: 1 / 1.294;

  display: flex;
  flex-direction: column;
  margin: 1% auto;
  /*  border: 1px solid pink;*/
  /*  overflow: hidden;*/
  padding: 1%;
  font-size: 10pt;
  break-before: always;
  /*  border: 1;*/
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  scroll-margin-top: 1in;
}

.first {
  break-before: avoid;
}

.main .row {
  flex-wrap: nowrap;
}

.body {
  line-height: 1;
  /*  margin: 0.5rem 0;*/
}

.header,
.footer {
  font-size: 12pt;
  margin: 6pt 0;
  background: transparent;
  position: relative;
}

/*.footer {
  position: absolute;
  bottom: 0;

}*/

.scrollShadow {
  position: absolute;
  bottom: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 162, 255, 0.5);
  height: 1px;
  /*  width: 100%;*/
  background: #00a2ff;
  display: block;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -moz-transition: width 0.5s;
}

.toastFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer .subFooter {
  margin: 0;
  position: relative;
  /*  position: absolute;
  bottom: 0;*/
}

.header h1 {
  text-align: center;
  margin: 0 0 6pt;
  font-size: 20pt;
}

.header h2 {
  text-align: center;
  margin: 0 0 4pt;
  font-size: 16pt;
  font-weight: 600;
}

.header .teamName,
.footer .signature,
.footer .yellowCard {
  text-align: left;
  display: flex;
  margin: 6pt 0;
}

.header .sessionInfo {
  text-align: left;
  display: flex;
  margin: 2pt 0;
}

.header .sessionInfo .sessionInfoLabel {
  flex-basis: 10%;
}

.header .sessionInfoValue {
  /*  border-bottom: 1px solid #000;*/
  flex-basis: 100%;
}

.footer .signature {
  position: relative;
}

.footer .yellowCard {
  flex-direction: row-reverse;
}

.footer .yellowCard input,
.footer .yellowCard label {
  cursor: pointer;
}

.header .teamNameLabel,
.footer .signatureLabel {
  flex-basis: 20%;
}

.footer .signatureLabel sup {
  position: absolute;
  top: 6pt;
}

.header .teamNameTeam,
.footer .signatureField {
  flex-basis: 80%;
  border-bottom: 1px solid #000;
  padding-left: 4pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer .signatureName {
  flex-basis: 80%;
  font-size: 8pt;
  line-height: 8pt;
  min-height: 8pt;
  /*  white-space: nowrap;*/
  /*  overflow: hidden;*/
  /*  text-overflow: ellipsis;*/
}

.footer .signatureNames {
  margin: 0 0 0pt 0;
  display: flex;
}

.header .matchDetailsShort {
  display: flex;
  margin: 12pt 0;
  flex-basis: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .matchDetailsLong {
  display: flex;
  margin: 12pt 0;
  flex-basis: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .matchDetailsLabel {
  flex-basis: 25%;
}

.header .matchDetailsShortField {
  flex-basis: 75%;
  border-bottom: 1px solid #000;
  padding-left: 4pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .matchDetailsLongField {
  flex-basis: 75%;
  border-bottom: 1px solid #000;
  padding-left: 4pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .matchDetailsEditButton {
  width: 24px;
  height: 24px;
  padding: 0 0.2rem 0.2rem;
  color: #00a2ff;
}

.squadLeft {
  /*  max-width: 50% !important; */
  padding-right: 4px !important;
}

.squadRight {
  /*  max-width: 50% !important;  */
  padding-left: 4px !important;
}

.score {
  text-align: right;
  width: 6%;
  min-width: 26pt;
}

.scoreND,
.scoreSB {
  text-align: right;
  width: 5%;
  min-width: 20pt;
}

.finalScore {
  text-align: right;
  width: 8%;
  min-width: 36pt;
}

.judgeRating {
  width: 25%;
}

tbody tr .aaScoreTd {
  width: 15%;
  padding: 0 !important;
}

tbody tr .aaScoreTd span {
  padding: 1pt 2pt;
  text-align: right;
  float: right;
}

.aaScore {
  text-align: right;
  position: relative;
  min-height: 10pt;
}

.aaScore span,
.name span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  /*  text-overflow: ellipsis;*/
  padding: 1pt 2pt !important;
}

.aaScore span {
  text-align: right;
}

.name span {
  text-align: left;
}

.rotationScore,
.teamScore {
  /*  font-size: 1.2rem;*/
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10pt;
  padding: 0 12pt;
  line-height: 1.5;
}

.rotationScore .rotationScoreLabel {
  flex-basis: content;
  padding-left: 6pt;
  padding-right: 6pt;
}

.rotationScore .rotationScoreValue {
  padding-left: 0;
  padding-right: 0;
  direction: rtl;
}

.teamScore .teamScoreLabel {
  flex-basis: content;
  /*justify-content: right;
  display: flex;
  */
  align-items: center;
}

.rotationScoreValue,
.teamScoreValue {
  flex-basis: 10%;
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.teamScoreValue {
  border: 1px solid #000;
  padding: 2pt 4pt;
  background: #eee;
  border-radius: 4pt;
  text-align: center;
  min-height: 32px;
  /*  min-height: 2.4rem;*/
}

.teamScoreValueEmpty {
  border: 1px solid transparent !important;
  background: none !important;
}

.timestamp {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 10pt;
  margin: 10pt 0;
  font-variant-numeric: tabular-nums;
}

.headJudge {
  font-size: 66%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
}

.signature h5 {
  margin-bottom: 0;
  font-size: 14pt;
}

.main .scoreSheetListTable {
  border: none;
  font-size: 12pt;
}

.main .reduceFont {
  font-size: 10pt !important;
}

.main .scoreSheetListTable td,
.main .scoreSheetListTable tr,
.main .scoreSheetListTable tbody td,
.main .scoreSheetListTable tbody tr {
  border: none;
  border-top: none !important;
}

.main .scoreSheetListTable thead th,
.main .scoreSheetListTable tbody td {
  padding: 4pt 4pt;
}

.main .scoreSheetListRank {
  text-align: right;
  width: 3%;
  padding: 4pt 0 !important;
}

.main .scoreSheetListScore {
  text-align: right;
  min-width: 42pt;
  font-variant-numeric: tabular-nums;
}

.main .scoreSheetListTeam {
  text-align: left;
  /*  width: 20%;*/
  min-width: 72pt;
  max-width: 120pt;
  white-space: nowrap;
  /*  overflow: hidden;*/
  /*  text-overflow: ellipsis;*/
}

.main .scoreSheetListTeam span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main .scoreSheetListOut {
  text-align: right;
  width: 10%;
}

.main .scoreSheetListTie {
  text-align: left;
  width: 3%;
  padding-left: 0pt !important;
  padding-right: 0pt !important;
}

.main .scoreSheetListTotal {
  text-align: right;
  border-left: 2px solid #dee2e6 !important;
  border-right: 2px solid #dee2e6 !important;
  font-weight: bold;
  width: 12%;
}

.main .scoreSheetListTotalEnd {
  text-align: right;
  border-left: 2px solid #dee2e6 !important;
  font-weight: bold;
  width: 12%;
  font-variant-numeric: tabular-nums;
}

.main tfoot {
  border-top: 2px solid #dee2e6;
}

.main tfoot td {
  padding: 4pt;
  font-weight: bold;
}

.main table {
  border: 1px solid #000;
  font-size: 8pt;
  margin-bottom: 2pt;
}

.main table tbody tr .apparatusName {
  writing-mode: vertical-rl;
  text-orientation: upright;
  vertical-align: middle;
  text-align: center;
  padding: 0 !important;
  width: 5%;
  min-width: 12pt;
  white-space: nowrap;
}

.main table tbody tr .apparatusNameSpan {
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding: 0 !important;
  white-space: nowrap;
}

.main table tbody .orderNum {
  text-align: center;
  width: 5%;
  white-space: nowrap;
  min-width: 12pt;
}

.main table tbody tr .name {
  position: relative;
  min-height: 10pt;
}

.main table tbody tr .nameTd {
  padding: 0;
  width: 100%;
}

.main table tbody tr .nameTdJudge {
  padding: 0;
  width: 70%;
}

.main table tbody td,
.main table tbody th,
.main tbody td,
.main tbody th {
  border: 1px solid #000;
  border-top: 1px solid #000 !important;
  font-variant-numeric: tabular-nums;
  padding: 1pt 3pt;
  vertical-align: middle;
  min-height: 10pt;
}

.scoreSheetSetup .customControl,
.scoreSheetSetup .customControl label {
  border: 0;
  height: auto;
  cursor: pointer;
  margin: 0.25rem 0;
}

.scoreSheetSetup .row {
  margin: 2rem 0;
}

.scoreSheetSetup label {
  width: 100%;
  margin: 0;
  font-size: 0.75rem;
  color: #333;
}

.scoreSheetSetup input {
  width: 100%;
  margin: 0 auto;
  height: 40px;
  padding: 10px !important;
  border: 1px solid #aaa;
  border-radius: 3px;
  background: rgba(250, 250, 250, 0.75);
}

.scoreSheetSetup select {
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 10px !important;
  height: 40px;
  background: rgba(250, 250, 250, 0.75);
  border: 1px solid #aaa;
  cursor: pointer;
  font-size: 1rem;
  color: #212529;
}

.scoreSheetSetup select option {
  padding: 0;
}

.scoreSheetUpButton,
.scoreSheetDownButton,
.scoreSheetPrintButton,
.scoreSheetSetupButton {
  /*  z-index: 999;*/
  position: absolute;
  top: 50%;
  border-radius: 25px;
  max-width: 2rem;
  max-height: 2rem;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
  transform: translate(0, -50%);
}

.scoreSheetUpButton {
  right: 11.5rem;
}

.scoreSheetDownButton {
  right: 9rem;
}

.scoreSheetSetupButton {
  right: 6.5rem;
}

.scoreSheetPrintButton {
  right: 4rem;
}

.scoreSheetSetupButton button,
.scoreSheetPrintButton button,
.scoreSheetUpButton button,
.scoreSheetDownButton button {
  font-size: 0.75rem;
  color: #ccc;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  text-align: left;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
}

.scoreSheetSetupButton button:disabled,
.scoreSheetPrintButton button:disabled,
.scoreSheetUpButton button:disabled,
.scoreSheetDownButton button:disabled {
  background-color: rgba(0, 0, 0, 0.4);
}

.scoreSheetUpButton button svg,
.scoreSheetDownButton button svg,
.scoreSheetSetupButton button svg,
.scoreSheetPrintButton button svg {
  font-size: 1.5rem;
  color: #eee;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scoreSheetUpButton button:hover,
.scoreSheetDownButton button:hover,
.scoreSheetSetupButton button:hover,
.scoreSheetPrintButton button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.scoreSheetUpButton button:hover:disabled,
.scoreSheetDownButton button:hover:disabled,
.scoreSheetSetupButton button:hover:disabled,
.scoreSheetPrintButton button:hover:disabled {
  background: rgba(0, 0, 0, 0.4);
}

.virtiusTitle {
  font-weight: 700;
  color: #5f5f5f;
}

.virtiusTitle span {
  color: #00a2ff;
}

.main .formLineupVerificationTable {
  border: none;
  font-size: 12pt;
}

.main .formLineupVerificationTable ol {
  /*  padding-inline-start: 14pt;*/
}

.main .formLineupVerificationTable td,
.main .formLineupVerificationTable tr,
.main .formLineupVerificationTable tbody td,
.main .formLineupVerificationTable tbody tr {
  border: none;
  border-top: none !important;
  vertical-align: baseline;
}

.main .formLineupVerificationTable thead th,
.main .formLineupVerificationTable tbody td {
  padding: 4pt 4pt;
}

.main .formLineupVerificationLineup {
  text-align: left;
  /*  width: 20%;*/
  min-width: 72pt;
  max-width: 120pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main .formLineupVerificationLineup span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main .formLineupVerificationUpdate li {
  line-height: 24pt;
}

@media print {
  .main {
    -webkit-print-color-adjust: exact; /* chrome */
    print-color-adjust: exact; /* firefox and IE */
    /*    display: grid;*/
    /*    height: 100vh;*/
    /*        line-height: 10pt;*/
    height: calc(100% - 0.5in);
    width: calc(100% - 0.5in);
    padding: 1%;
  }

  .mainM table {
    line-height: 12pt !important;
    font-size: 10pt !important;
  }

  .mainF table {
    line-height: 16pt !important;
    font-size: 12pt !important;
  }

  .mainM .teamScoreSheet table {
    line-height: 11pt !important;
    font-size: 11pt !important;
  }

  .mainF .teamScoreSheet table {
    line-height: 12pt !important;
    font-size: 12pt !important;
  }

  .mainF .reduceFont table {
    line-height: 9pt !important;
    font-size: 9pt !important;
  }

  .mainF .signatureName,
  .mainM .signatureName {
    line-height: 10pt !important;
    font-size: 10pt !important;
  }

  .lineupForm table {
    line-height: 14pt !important;
    font-size: 14pt !important;
  }

  .mainM table tbody tr .name,
  .mainM .aaScore {
    min-height: 12pt;
  }

  .mainF table tbody tr .name,
  .mainF .aaScore {
    min-height: 14pt;
  }

  @page {
    size: 8.5in 11in;
  }

  .noPrint {
    display: none;
  }

  .header .matchDetailsEditButton,
  .scoreSheetUpButton,
  .scoreSheetDownButton,
  .scoreSheetSetupButton,
  .scoreSheetPrintButton {
    display: none;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .body {
      /*      line-height: 5pt;*/
      /*      line-height: 1.5;*/
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .body {
      /*      line-height: 10pt;*/
    }
  }
}

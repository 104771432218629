.dashboard-container {
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.search-container .form-control {
  width: 100% !important;
}

.dashboard-header {
  margin-bottom: 20px;
}

.dashboard-header .d-flex {
  position: relative;
}

.dashboard-header h2 {
  margin-bottom: 15px;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dashboard-alert {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-content {
  flex: 1;
  overflow: auto;
}

.dashboard-content table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  border-bottom: 2px solid #dee2e6;
}

.date-picker-pill {
  border: none;
  background: none;
  padding: 0;
}

.date-picker-pill input {
  display: none;
}

.react-datepicker-popper {
  z-index: 1000;
}

.dashboard-content table tr:hover {
  cursor: pointer;
}

tr.clickable-row:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.session-modal {
  height: 90vh;
  display: flex;
}

.session-modal .modal-dialog {
  margin: 0 auto;
  display: flex;
  max-height: 90vh;
  width: 100%;
}

.session-modal .modal-content {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.session-modal .modal-body {
  background-color: white;
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.streams-cell {
  gap: 0.25rem;
}

.rtn-id-input {
  width: 100px !important;
  min-width: 100px;
}

.streams-cell .stream-add-button {
  border-radius: 50%;
  line-height: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.2rem;
  padding: 0;
}

td .dl-button {
  border: none;
}

.sideScrollMenuContainer {
  padding: 0 15px 15px 15px;
  position: relative;
}

.sideScrollMenuButton {
  border-radius: 4rem;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  margin-right: 0.5rem;
  background: #eee;
  white-space: nowrap;
}

.sideScrollMenuContainer .arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  user-select: none;
  position: absolute;
  z-index: 10;
  border: none;
  padding: 0.2rem;
  font-size: 0.8rem;
  width: 3rem;
  height: 100%;
}
.sideScrollMenuContainer .arrow svg {
  height: 100%;
}

.sideScrollMenuContainer .selected,
.sideScrollMenuContainer .selected:focus,
.sideScrollMenuContainer .selected:active {
  background: #aaa;
}

.sideScrollMenuContainer
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.sideScrollMenuContainer .react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scoreboardModal .filterMenuContainer {
  padding: 0;
  position: relative;
  margin-bottom: 1rem;
}

.scoreboardModal
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.scoreboardModal .react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.scoreboardModal .filterMenuContainer .arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  user-select: none;
  position: absolute;
  z-index: 10;
  border: none;
  padding: 0.2rem;
  font-size: 0.8rem;
  width: 3rem;
  height: 100%;
}

.scoreboardModal .filterMenuContainer .arrow svg {
  height: 100%;
}

.scoreboardModal .scoreboardHeader {
  font-weight: 600;
  justify-content: left;
  font-size: 1.2rem;
  color: #333;
  position: relative;
  display: block;
  background-color: var(--background-haze);
}

.scoreboardModal .scoreboardFilterButton {
  border-radius: 4rem;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  margin-right: 0.5rem;
  background: #eee;
  white-space: nowrap;
  margin: 0.2rem;
}

.scoreboardModal .selected,
.scoreboardModal .selected:focus,
.scoreboardModal .selected:active {
  background: #aaa;
}

.scoreboardModal table thead tr th {
  white-space: nowrap;
  position: relative;
}

.scoreboardModal table tbody tr td {
  white-space: nowrap;
}

.scoreboardLogo {
  max-width: 2rem;
  margin-right: 0.5rem;
}

.scoreboardModal .leaderboardApparatusList {
  margin: 0;
  height: 100%;
}

.scoreboardModal .leaderboardApparatusList .col {
  padding: 0;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 0.4rem;
  margin: 0 1px;
  font-size: 0.8rem;
  height: 100%;
}

.scoreboardModal .leaderboardApparatusList .empty {
  border: 1px solid transparent;
  min-width: 1.2rem;
}

.scoreboardModal table td {
  vertical-align: middle;
}

.scoreboardModal .search-container {
  right: calc(72px + 2rem);
}

.scoreboardModal .exportData button {
  min-height: 36px;
  min-width: 36px;
  background: #eee;
  border: 1px solid #eee;
  right: calc(36px + 1.5rem);
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  color: rgba(150, 150, 150, 1);
}

.scoreboardModal .toggleTimer button {
  min-height: 36px;
  min-width: 36px;
  background: #eee;
  border: 1px solid #eee;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  color: rgba(150, 150, 150, 1);
}

.scoreboardModal .toggleTimer .toggled {
  background: #ccc;
  color: #000;
}

.scoreboardModal .modal-content {
  min-height: calc(100vh - 3.5rem);
  border-radius: 25px;
}

.number {
  display: block;
  text-align: right;
  overflow: auto;
}

.decimal {
  width: 100px;
  float: right;
  text-align: left;
}

.columnRight {
  display: block;
  font-variant-numeric: tabular-nums;
  text-align: right;
  margin-right: 1rem;
}

.columnRightHeader {
  font-variant-numeric: tabular-nums;
  text-align: right;
  width: 100%;
}

.headerText {
  width: 100%;
  display: flex;
}

.headerSortIndicator {
  width: 1rem;
  text-align: center;
}

.columnLeftHeader {
  text-align: left;
}

.superScriptCount {
  vertical-align: super;
  color: #aaa;
  font-size: x-small;
}

.scoreboardFullscreen .modal-content {
  border-radius: 0 !important;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: var(--background);
  color: var(--text-primary);
  font-size: 1.8rem;
}

.scoreboardFullscreen .modal-content .table,
.scoreboardFullscreen .modal-content .scoreboardHeader {
  color: var(--text-primary);
  font-size: 1.4rem;
}

.scoreboardSessionName {
  padding-left: 0.4rem;
  font-weight: 400;
  font-style: italic;
}

[font-theme='800'] .scoreboardFullscreen .modal-content .table,
[font-theme='700'] .scoreboardFullscreen .modal-content .table,
[font-theme='600'] .scoreboardFullscreen .modal-content .table,
[font-theme='800'] .scoreboardFullscreen .modal-content .scoreboardSessionName,
[font-theme='700'] .scoreboardFullscreen .modal-content .scoreboardSessionName,
[font-theme='600'] .scoreboardFullscreen .modal-content .scoreboardSessionName,
[font-theme='800'] .scoreboardFullscreen .modal-content .scoreboardHeader,
[font-theme='700'] .scoreboardFullscreen .modal-content .scoreboardHeader,
[font-theme='600'] .scoreboardFullscreen .modal-content .scoreboardHeader {
  font-weight: var(--font-weight);
  font-size: 2.4vw;
}

[font-theme='800'] .scoreboardFullscreen .scoreboardLogo,
[font-theme='700'] .scoreboardFullscreen .scoreboardLogo,
[font-theme='600'] .scoreboardFullscreen .scoreboardLogo,
[font-theme='800'] .scoreboardFullscreen .scoreboardHeaderLogo,
[font-theme='700'] .scoreboardFullscreen .scoreboardHeaderLogo,
[font-theme='600'] .scoreboardFullscreen .scoreboardHeaderLogo {
  width: 3vw;
  height: 100%;
  max-width: 3vw;
}

[data-theme='dark'] .scoreboardFullscreen .scoreboardLogo,
[data-theme='dark'] .scoreboardFullscreen .scoreboardHeaderLogo {
  background: radial-gradient(#eee 80%, #eee, #333);
  box-shadow: 0vw 0vw 0.1vw white;
  padding: 0.25vw;
  border-radius: 0.25vw;
  min-height: 100%;
}

.scoreboardFullscreen .scoreboardLogo {
  width: 2rem;
  height: 100%;
}

.scoreboardFullscreen .scoreboardHeaderLogo {
  /*  height: 1.8rem;*/
  width: 2.4rem;
  height: 100%;
}

.scoreboardFullscreen .scoreboardFilterButton {
  border-color: transparent;
}

[font-theme='800'] .scoreboardFullscreen .scoreboardFilterButton,
[font-theme='700'] .scoreboardFullscreen .scoreboardFilterButton,
[font-theme='600'] .scoreboardFullscreen .scoreboardFilterButton {
  font-weight: var(--font-weight);
  font-size: 2vw;
  border-radius: 1vw;
  color: #666;
}

.scoreboardRoutineStatus {
  font-size: 0.8rem;
}

[font-theme='800'] .scoreboardFullscreen .scoreboardRoutineStatus,
[font-theme='700'] .scoreboardFullscreen .scoreboardRoutineStatus,
[font-theme='600'] .scoreboardFullscreen .scoreboardRoutineStatus {
  font-weight: var(--font-weight);
  font-size: 2vw;
}

[font-theme='800'] .scoreboardFullscreen .leaderboardApparatusList .col,
[font-theme='700'] .scoreboardFullscreen .leaderboardApparatusList .col,
[font-theme='600'] .scoreboardFullscreen .leaderboardApparatusList .col {
  padding: 0.1vw;
  text-align: center;
  border-radius: 0.4vw;
  margin: 0.1vw;
  font-size: 1.8vw;
  height: 100%;
  min-height: 100%;
  line-height: 2.4vw;
}

.scoreboardFullscreen .leaderboardApparatusList .col {
  padding: 0vw;
  text-align: center;
  border-radius: 0.4vw;
  margin: 0.2vw;
  font-size: 1vw;
  height: 100%;
  min-height: 100%;
  line-height: 2vw;
}

.scoreboardFullscreen .filterMenuContainer .arrow {
  display: none !important;
}

.scoreboardFullscreen .headerSortIndicator,
.scoreboardFullscreen .superScriptCount,
.scoreboardFullscreen .scoreboardFunctions,
.scoreboardFullscreen .scoreboardFooter {
  display: none;
}

[data-theme='dark']
  .scoreboardFullscreen
  .filterMenuContainer
  .scoreboardFilterButton {
  background-color: #666;
  color: #000;
}

[data-theme='dark'] .scoreboardFullscreen .filterMenuContainer .selected {
  background-color: #eee;
}

[data-theme='dark'] .scoreboardFullscreen .table-hover tbody tr:hover {
  color: #fff;
  /*background-color: #222; */
}

.scoreboardFullscreen .superScriptCount {
  vertical-align: super;
  color: #888;
  font-size: x-small;
}

.scoreboardFullscreen .expansionArrow {
  display: none;
}

.scoreboardFullscreen table {
  cursor: none;
}

.scoreboardHeaderLogo {
  height: 1.6rem;
  margin: 0 0.5rem;
  padding: 0.1rem;
}

.scoreboardHeaderScores {
  float: right;
  display: none;
}

.scoreboardHeaderScores span {
  margin: 0 0 0 1rem;
}

.scoreboardFullscreen .scoreboardHeaderScores {
  display: block;
}

@media (max-width: 576px) {
  .exportData,
  .toggleTimer {
    display: none;
  }

  .scoreboardModal {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .scoreboardModal .modal-content {
    min-height: calc(95vh - 3.5rem);
    border-radius: 25px;
  }

  .scoreboardFunctions {
    display: none;
  }
}

@media (max-width: 375px) {
  .exportData,
  .toggleTimer {
    display: none;
  }

  .scoreboardModal {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .scoreboardModal .modal-content {
    min-height: calc(95vh - 3.5rem);
    border-radius: 25px;
  }

  .scoreboardFunctions {
    display: none;
  }
}

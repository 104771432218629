.roundSummary {
  background-color: var(--background-haze);
  font-size: 1.4rem;
  color: var(--text-secondary);
  min-height: 54px;
}

.left .roundSummary {
  box-shadow: -1px 0px 2px -2px rgba(0, 0, 0, 0.2);
  border-radius: 0 4px 4px 0;
}

.right .roundSummary {
  box-shadow: 1px 0px 2px -2px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
}

.left .roundSummary .team span {
  padding-left: 0.75rem;
}

.right .roundSummary .team span {
  padding-right: 0.75rem;
}

.left .roundSummary .score {
  border-radius: 0 4px 4px 0;
  background: var(--header-gradient-left);
}

.right .roundSummary .score {
  border-radius: 4px 0 0 4px;
  background: var(--header-gradient-right);
}

.roundSummary .logo {
  text-align: center;
}

[font-theme='800'] .roundSummary,
[font-theme='700'] .roundSummary,
[font-theme='600'] .roundSummary,
[font-theme='800'] .roundSummary .score,
[font-theme='700'] .roundSummary .score,
[font-theme='600'] .roundSummary .score,
[font-theme='800'] .roundSummary .team,
[font-theme='700'] .roundSummary .team,
[font-theme='600'] .roundSummary .team {
  font-weight: var(--font-weight);
  font-size: 2.4vw;
}

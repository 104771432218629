.experimental {
  background: var(--background);
  /*  font-size: 4rem !important;*/
}

.experimental .minimize {
  width: 0;
  height: 0;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.experimental .minimize {
  margin-top: 0 !important;
}

.experimental .mainRotations .hideCardGap,
.experimental .mainRotations .hideCardGap .card-body {
  margin-top: 0;
  border: none;
  min-height: 0;
}

.experimental .card-body,
.experimental .card {
  background: var(--background);
  border-color: var(--background);
}

.experimental .competitorLine .score {
  background: var(--background-solid);
}

.experimental .soloRoundCount {
  background: var(--background) !important;
}

.experimental .mainRotations {
  margin-bottom: 1vh;
}

.experimental .rotationTitle {
  display: flex;
  align-items: center;
}

.experimental .mainRotations .card-body {
  margin-top: 1vh;
}

.experimental .penaltyIndicator {
  /*  top: 2px;*/
  right: 2px;
}

.experimental .stickIndicator {
  right: 2px;
}

.experimental .card-footer {
  padding: 0;
}

.experimental .roundSummary {
  min-height: 2rem;
  height: 2rem;
  line-height: 1;
}

.experimental .roundSummary .team {
  font-size: 1rem;
  text-align: right;
  white-space: nowrap;
  direction: rtl;
}

.experimental .roundSummary .score {
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  direction: rtl;
  /*  text-align: right;*/
}

.experimental .mainRotations .rotationButtons {
  display: flex;
  flex-direction: row-reverse;
}

.experimental .mainRotations .rotationButtons button {
  /*    text-decoration: none */
}

.experimental .mainRotations .rotationButtons button {
  float: right;
  display: block;
  margin-left: 1rem;
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  line-height: 1;
  color: #333;
  height: 2rem;
}

.experimental .quadHeader {
  top: -72px;
  position: absolute;
  display: none;
}

.experimental .rotationRow {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  display: block;
}

.experimental .rotationRowCol {
  padding-left: 0;
  padding-right: 0;
}

.experimental .quad {
  .mainRotations .card-body {
    margin-top: 0;
  }
  .rotationTitle {
    display: none;
  }
  .cardHeaderApparatus span,
  .cardHeaderTeam span {
    font-weight: 700;
    text-wrap: nowrap;
  }
  .competitorList {
    line-height: 0;
    /*    height: 10vw;*/
    /*    height: 40vh;*/
  }
  .competitorList .pending {
    font-size: 1.8vw;
  }
  .competitorList li {
    margin-bottom: 0;
    height: 3vw;
  }
  .competitorList .competitorLine {
    height: 2vw;
  }
  .competitorList .athlete input {
    font-size: 1.8vw;
  }
  .competitorList .competitorLine label {
    font-size: 1vh;
    margin-top: 0;
  }
  .competitorList .dScoreIndicator {
    font-size: 1.4rem;
  }
  .competitorList .stickIndicator,
  .competitorList .penaltyIndicator {
    right: -1vw;
    width: 1rem;
  }
  .cardHeaderApparatus {
    font-size: 1.8vw;
  }
  .cardHeaderTeam {
    font-size: 1.8vw;
  }
  .competitorList .scorecard {
    font-size: 1.8vw;
    width: 120%;
  }
  .cardHeaderTeam img {
    max-height: 1.8vw;
    height: 1.8vw;
  }
  .roundSummary .score,
  .roundSummary .team {
    font-size: 1.8vw;
  }
  .roundSummary .spacer,
  .roundSummary .score,
  .roundSummary .team {
    padding-left: 0;
    padding-right: 0;
  }
  .roundSummary .team {
    flex: 0 0 36%;
    max-width: 36%;
  }
  .roundSummary .score {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .roundSummary {
    height: 3vw;
  }
  .card-footer {
    z-index: 1;
  }
}

.cardHeaderApparatusText {
  font-size: 1.4rem;
  display: flex;
}

.cardHeaderApparatus {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  text-align: left;
}

.cardHeaderApparatus svg {
  height: 100%;
  max-height: 2rem;
  min-width: 2rem;
  margin: 0 1rem 0 0.1em;
  max-width: 2rem;
  /*  min-height: 2rem;*/
}

.cardHeaderTeam {
  justify-content: right;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  text-align: right;
}

.cardHeaderTeam img {
  /*  min-width: 2rem;*/
  max-height: 2rem;
  /*  width: 2rem;*/
  /*  height: 100%;*/
  aspect-ratio: auto;
}

.mainRotations .currentRotation {
  /*  background-color: #48a0f8;*/
  background-color: #555;
  color: #ccc;
}

.mainRotations .card-header {
  padding: 0.25rem 0.5rem;
}

.mainRotations .card-group .card .headerButton {
  color: black;
  min-height: 3rem;
}

.mainRotations .card-header:hover {
  cursor: pointer;
}

.mainRotations .card-body {
  padding: 0;
}

.mainRotations .competitorList .athlete input {
  /*  font-size: 1rem;*/
  /*  padding: 0;*/
}

.mainRotations .competitorList .buttonContainer button {
  z-index: 1;
}

.experimental {
  margin-bottom: 100px;
}

.experimental .roundcount .configureButton,
.experimental .roundcount .table tbody td,
.experimental .roundcount table td,
.experimental .roundcount .roundBubble-menu table tbody tr td button {
  padding: 0.1rem;
  border: none;
}

.scoreboard .footerButton {
  min-height: 66%;
  min-width: 66%;
}

.scoreboard .activated {
  opacity: 1 !important;
}

.experimental .mainRotations .evalSlabCard {
  display: flex;
}

@media (max-width: 576px) {
  .experimental .soloRoundCount {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .experimental .competitorList .athlete input {
    display: block !important;
    font-size: 1rem;
  }

  .experimental .competitorList .athlete .athleteName {
    display: none !important;
  }

  .experimental .competitorList .competitorLine {
    /*max-width: 100%;
    flex: 0 0 100%;*/
  }

  .experimental .competitorList .competitorLine .logo,
  .experimental .competitorList .exhibition .logo {
    background: inherit;
    position: relative;
    max-width: 100% !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .experimental .competitorList .athlete {
    max-width: 66%;
    flex: 0 0 66%;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
  }

  .experimental .competitorList .athleteName {
    font-size: 1rem;
  }

  .experimental .competitorList .scorecard {
    font-size: 1rem;
  }

  .experimental .cardHeaderApparatus span {
    display: none;
  }

  .experimental .roundSummary .score {
    max-width: 30%;
    flex: 0 0 30%;
  }
  /*.experimental .roundSummary .spacer {
    display: none;
  }*/

  .experimental .roundSummary .team {
    max-width: 36%;
    flex: 0 0 36%;
    display: flex !important;
  }

  .experimental .mainRotations h4 {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .experimental .mainRotations .rotationButtons button {
    font-size: 0.8rem;
    white-space: nowrap;
    margin-left: 0rem;
  }

  .experimental .roundcount .roundBubble-menu {
    /*    transform: translate(-100%) !important;*/
    /*    left: 0;*/
    position: fixed !important;
  }
}

@media (max-width: 375px) {
  .experimental .competitorList .athlete {
    max-width: 60%;
    flex: 0 0 60%;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
  }

  .experimental .competitorList .athlete .athleteName {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .experimental .rotationRow {
    display: flex;
  }

  .experimental .cardHeaderApparatus span {
    display: none;
  }

  .experimental .soloRoundCount {
    max-width: 15%;
    flex: 0 0 15%;
  }

  .experimental .soloRoundCount .roundcount .roundBubble button {
    /*    min-width: 150px !important;*/
  }

  .experimental .competitorList .competitorLine {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .experimental .roundSummary .team {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .experimental .roundSummary .spacer {
    display: none;
  }

  .experimental .roundSummary .team {
    max-width: 66%;
    flex: 0 0 66%;
    display: flex !important;
  }

  .experimental .roundSummary .score {
    max-width: 33%;
    flex: 0 0 33%;
  }
}

@media (min-width: 768px) {
  .experimental .cardHeaderApparatus span {
    display: block;
  }

  .experimental .roundSummary .spacer {
    display: flex;
  }

  .experimental .roundSummary .team {
    max-width: 30%;
    flex: 0 0 30%;
    display: flex !important;
  }

  .experimental .roundSummary .score {
    max-width: 20%;
    flex: 0 0 20%;
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  .experimental .roundSummary .team {
    max-width: 33%;
    flex: 0 0 33%;
    display: flex !important;
  }

  .experimental .roundSummary .score {
    max-width: 20%;
    flex: 0 0 20%;
  }
}

.triggerPanelCol {
  height: 100%;
}

.tPanelRowStyle {
  font-size: 0.9rem;
  margin: 0 auto;
  font-family: 'Product Sans';
  color: #666;
  transition: color 0.6s;
  /*padding-bottom: 12px;*/
  padding-top: 12px;
}

.tPanelRowStyle button {
  margin: 0 0.3rem;
  vertical-align: middle;
  border-radius: 6px;
  padding: 0.2rem;
  font-size: 0.9rem;
  background: #f8f8f8;
  border: none;
  min-width: 10%;
}

.tPanelRowStyle .btn-group button {
  margin: 0;
  padding: 0 0.5rem;
}

.tPanelRowStyle .btn-group .selectedStream {
  color: #000;
  opacity: 1 !important;
  font-weight: 600;
}

.tPanelStart {
  box-shadow: 0.25rem 0rem 0.25rem -0.25rem hsl(0deg 0% 39% / 15%);
  text-align: left;
}

.tPanelEnd {
  box-shadow: -0.25rem 0rem 0.25rem -0.25rem hsl(0deg 0% 39% / 15%);
  text-align: right;
  direction: rtl;
}

.tPanelRowButtons {
  display: flex;
  justify-content: center;
}

@media (max-width: 375px) {
  .tPanelRowStyle .infoButton,
  .tPanelRowStyle .discordButton {
    display: none;
  }
}

@media (max-width: 576px) {
  .tPanelRowStyle button {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .tPanelRowStyle button {
    font-size: 0.9rem;
  }
}

.tPanelRowButtons .discordButton svg {
  width: 1rem;
  height: 1rem;
  margin-bottom: 2px;
}

#admin-session-status-menu,
#admin-session-judgetype-menu {
  border: none;
  font-size: 0.9rem;
  padding: 0;
}

#admin-session-status-menu:hover,
#admin-session-judgetype-menu:hover {
  cursor: pointer;
}

#admin-session-status-menu:active,
#admin-session-status-menu:focus,
#admin-session-judgetype-menu:active,
#admin-session-judgetype-menu:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

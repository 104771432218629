.roundcount {
  height: 100%;
}

.roundcount button {
  border-radius: 0;
  background-color: var(--background);
  height: 72px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
  font-family: 'Product Sans';
  color: var(--text-primary);
  transition: none;
}

.roundcount button:hover {
  background: radial-gradient(rgba(100, 100, 100, 0.15), transparent 60%);
  color: var(--text-primary-hover);
}

.roundcount button:focus {
  box-shadow: none;
}

.roundcount .roundBubble-menu {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid black;
  background: none;
  min-width: 320px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding-top: 0;
}

.roundcount .centerMenu {
  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  transform: translate(-50%) !important;
}

.roundcount .roundBubble-menu .selectedRound {
  color: #111;
  text-transform: uppercase;
}

.roundcount .roundBubble-menu .selectedApparatus {
  color: #111;
  background: linear-gradient(90deg, #eee 10%, transparent 50%, #eee 90%);
}

.experimental .roundcount .roundBubble-menu .selectedLogo {
  border: 1px dashed #aaa;
}

.roundcount .roundBubble-menu .notAvailable {
  opacity: 0.3;
}

.roundcount .roundBubble-menu button {
  border-radius: 0;
  font-size: 1.2rem;
  text-transform: capitalize;
  height: auto;
  /*  background: transparent;*/
  background: var(--background);
  color: #aaa;
  padding: 0 0.5rem;
}

.roundcount .roundBubble .dropdown-toggle::after {
  position: absolute;
  bottom: 8%;
  right: 50%;
  transform: translate(50%, 0);
}

.scoreboardView .roundcount .roundBubble .dropdown-toggle::after {
  display: none;
}

[font-theme='800'] .roundcount button,
[font-theme='700'] .roundcount button,
[font-theme='600'] .roundcount button {
  font-weight: var(--font-weight);
}

.roundcount table {
  margin: 0;
  border-collapse: collapse;
}

.roundcount table th {
  padding: 0;
}

.roundcount table td,
.roundcount .configureButton {
  padding: 0;
  border: none;
  text-align: center;
  vertical-align: middle;
}

.roundcount .configureButton {
  min-height: 36px;
}

.roundcount table td tr {
  background: #fff;
}

.roundcount table td button {
  min-height: 36px;
}

.roundcount table td img {
  max-height: 64px;
  max-width: 50%;
  margin: 0 auto;
  object-fit: contain;
  padding: 0.03rem;
}

.roundcount table tbody td {
  padding: 0.48rem;
}

.roundcount .notConfigured {
  background: #fff;
}

.roundcount .notConfigured .cfgRotations {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: none;
}

.roundcount .roundBubble-menu table tbody > .selectedRound {
  /*  background: linear-gradient(30deg, #ddd, 20%, transparent, 80%, #ddd);*/
  background: linear-gradient(180deg, #eee 0%, transparent 50%, #eee 100%);
  border: none;
}

.roundcount .roundBubble-menu table tbody tr td button {
  background: transparent;
}

@media (max-width: 576px) {
  .roundcount .roundBubble-menu {
    min-width: 75vw !important;
    top: auto !important;
  }

  .roundcount .roundBubble-menu {
    max-width: 60vw;
    min-width: 20rem;
    /*    top: -2px !important;*/
    transform: translateY(0) !important;
  }

  .roundcount .centerMenu {
    -webkit-transform: translate(-50%, 0) !important;
    -o-transform: translate(-50%, 0) !important;
    transform: translate(-50%) !important;
  }
}

@media (max-width: 375px) {
  .roundcount .roundBubble-menu {
    min-width: 100vw !important;
  }
}

@media (max-width: 320px) {
  .roundcount .roundBubble-menu {
    min-width: 320px !important;
  }
}

@media (min-width: 576px) {
  .roundcount .roundBubble-menu {
    max-width: 60vw;
    min-width: 20rem;
    top: auto !important;
    transform: translateY(0) !important;
  }

  .roundcount .centerMenu {
    -webkit-transform: translate(-50%, 0) !important;
    -o-transform: translate(-50%, 0) !important;
    transform: translate(-50%) !important;
  }
}

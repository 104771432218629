.producerLoadingLogo svg {
  height: 1.5vh;
  float: right;
}

.producerLoadingText {
  font-size: 1.5vh;
  line-height: 1.5vh;
}

.producerLoadingText span {
  margin-right: 1vh;
}

.createOrJoinForm .setupFormLine {
  width: 80%;
}
.createOrJoinForm .createSoloButton {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 auto;
}

.createOrJoinForm .createDualButton {
  width: 155px;
  height: 80px;
  border-radius: 80px;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-left: 5px;
}

.createOrJoinForm .createMultiButton {
  width: 240px;
  height: 80px;
  margin: 0 auto;
  border-radius: 80px;
  font-weight: 600;
  font-size: 1.2rem;
}

.createOrJoinForm .createButtonBackground {
  width: 240px;
  height: 80px;
  border-radius: 80px;
  background: rgba(252, 253, 254);
  margin: 0.5rem auto;
}

.createOrJoinForm .genderSelect {
  margin: auto;
  width: 50%;
}

.createOrJoinForm .genderSelect button {
  width: 100px;
  font-weight: 600;
  height: 80px;
}

.createOrJoinForm .genderSelect .male {
  border-radius: 20px 20px 50px 50px;
}

.createOrJoinForm .genderSelect .female {
  border-radius: 50px 50px 20px 20px;
}

.createOrJoinModal .modal-body {
  min-height: 200px;
}

.createOrJoinForm .multi.male {
  width: 100%;
}

.createOrJoinForm .multi.male .col {
  min-width: 16%;
}

.createOrJoinForm .multi.female .col {
  min-width: 25%;
}

.createOrJoinForm .multi.female {
  width: 80%;
}

.createOrJoinForm .multi.male .col {
  padding: 0.2rem;
}

.createOrJoinForm .multi.female .col {
  padding: 0.2rem;
}

.popover-header .scoreEdit {
  display: flex;
  border-radius: 8px;
  margin-left: -1rem;
  margin-right: 1rem;
}

.scoreEdit svg {
  height: 100%;
}

@media (max-width: 576px) {
  /*  #popover-scorecard .scoreDelta {
    flex: 0 0 22%;
    max-width: 22%;
  }*/
  /*  #popover-scorecard .*/
}

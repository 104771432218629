.right .evaluator {
  background: linear-gradient(
    90deg,
    rgba(230, 230, 230, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-size: 100%;
  position: relative;
  border-radius: 12px;
}

.left .evaluator {
  /*background: linear-gradient(90deg, transparent, rgba(230,230,230,0.5) );*/
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(230, 230, 230, 0.5)
  );
  position: relative;
  border-radius: 12px;
}

.evaluator .evalscore {
  display: inline-flex;
  vertical-align: middle;
}

.evaluator span {
  /*position: absolute;
  top: 0.2rem;
  left: 1rem;*/
  font-size: 1rem;
  color: var(--text-tertiary);
  text-align: center;
}

.dualEval .evaluator button {
  width: 4vw;
}

.evaluator button {
  height: 8vh;
  width: 8vw;
  /*  width: 54px;*/
  margin: auto;
  border-radius: 100px;
  padding: 0;
  font-size: 1.2rem;
  position: relative;
  min-height: 54px;
  min-width: 54px;
}

.evaluator .ND,
.evaluator .SB {
  height: 8vh;
  width: 8vh;
  margin: auto;
  max-width: 45%;
}

.evaluator .lockIndicator {
  /*width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #ffc107; 
  position: absolute;
  bottom: 0;
  right: -8px;*/

  width: 80%;
  height: 4px;
  background-color: #ffc107;
  position: absolute;
  top: -3px;
  right: 10%;
  z-index: 10;
}

.evaluator .SB .lockIndicator {
  background-color: #28a745 !important;
}

.evaluator .ND .lockIndicator {
  background-color: #dc3545 !important;
}

.evaluator .D .lockIndicator,
.evaluator .D1 .lockIndicator,
.evaluator .D2 .lockIndicator {
  background-color: #007bff !important;
}

.evaluator .evalPanel,
.evaluator .evalExtras {
  display: flex;
}

.right .evaluator .evalPanel,
.right .evaluator .evalExtras {
  flex-direction: row-reverse;
}

.evaluator .evalPanel button {
  background: none;
  border: none;
  color: var(--text-primary);
  border-radius: 0;
  /*box-shadow: 0 0.25rem 0.2rem -0.2rem rgba(100,100,100,0.8);*/
}

.evaluator .evalExtras button:hover:not(:disabled):not(.disabled),
.evaluator .evalPanel button:hover:not(:disabled):not(.disabled) {
  background: #eee;
  /*border: 1px solid #333;*/
}

[data-theme='dark']
  .evaluator
  .evalExtras
  button:hover:not(:disabled):not(.disabled),
[data-theme='dark']
  .evaluator
  .evalPanel
  button:hover:not(:disabled):not(.disabled) {
  background: radial-gradient(#333, transparent);
  /*border: 1px solid #333;*/
}

.evaluator .evalExtras button:active,
.evaluator .evalExtras button.active:focus,
.evaluator .evalExtras button:active:focus,
.evaluator .evalExtras button:focus,
.evaluator .evalPanel button:active,
.evaluator .evalPanel button.active:focus,
.evaluator .evalPanel button:active:focus,
.evaluator .evalPanel button:focus {
  box-shadow: none !important;
}

.evaluator .evalExtras button {
  background: none;
  border: none;
  color: var(--text-primary);
  border-radius: 0;
  /*box-shadow: 0 0.25rem 0.2rem -0.2rem rgba(100,100,100,0.8); */
}

.evaluator .tscore .finalize {
  height: 100%;
  width: 100%;
  border-radius: 0;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  border: none;
}

.container {
  background-color: transparent;
  /*  background-color: #04f404;*/
  color: var(--text-primary);
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.selected {
  background-color: #6c757d;
  opacity: 0.5;
}

.menu {
  background-color: black;
  color: #fff;
  padding: 1rem;
}

[data-theme='dark'] .container {
  background-color: var(--background-haze);
}

.display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*cursor: none;*/
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.display .body {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.overlayTemplate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.scoreStreamHeader {
  padding: 2% 14%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.scoreStreamTitle {
  font-size: 4vw;
  font-weight: 600;
  white-space: nowrap;
}

.scoreStreamRotation {
  font-size: 3vw;
  font-weight: 600;
}

.scoreStreamFooter {
  width: 100%;
  height: 100%;
  position: relative;
}

.scoreStreamFooterTeamScores {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  font-size: 3vw;
}

.scoreStreamFooterScore {
  padding: 0 0.25vw;
}

.scoreboardHeaderLogo {
  height: 6vw !important;
  width: 6vw !important;
}

.poweredTag {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0, -50%);
  height: 5.5vw;
}

.rotationTitle {
  position: absolute;
  bottom: 15%;
  right: 0;
  width: 20%;
  height: 5%;
  background-color: #111;
  display: grid;
  align-items: center;
  color: #fff;
}

.rotationTitle h4 {
  font-size: 1.6vw;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.scoreboardTeams {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 15%;
}

.scoreboardText {
  font-size: 1.5vw;
  padding: 0;
}

.virtiusLogo {
  position: absolute;
  width: 10vw;
}

.settingButton {
  margin-right: 1vw;
}

.L3 {
  position: absolute;
  bottom: 5.25%;
  left: 50%;
  transform: translate(-46.9%);
  width: 64.8%;
  height: 11%;
  display: flex;
}

.L3 div {
  width: 100%;
  display: block;
  color: #fff;
}

.L3 .text {
  flex-basis: 72%;
}

.L3 .logo,
.LineupSlab .headerLogo {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  /*  background: #fff;*/
}

.L3 .logo img,
.LineupSlab .headerLogo svg {
  max-height: 75%;
  margin: auto;
  object-fit: contain;
  max-width: 75%;
}

.L3 .title {
  font-size: 1.66vw;
  line-height: 1.66vw;
  font-weight: 600;
  margin: 0.2vw 0 0 0;
  min-height: 1.66vw;
  color: #000000;
}

.L3 .main {
  font-size: 2.4vw;
  line-height: 2.4vw;
  font-weight: 700;
  margin: 0.2vw 0 0 0;
  min-height: 2.4vw;
}

.L3 .sub {
  font-size: 1.66vw;
  line-height: 1.66vw;
  font-weight: 400;
  margin: 0 0 0.2vw 0;
  min-height: 1.66vw;
}

.LineupSlabV3 {
  position: absolute;
  right: 0%;
  top: 0%;
  width: 20%;
  height: 40%;
  color: #fff;
  background-color: #333;
}

.LineupSlabV3 .headerLogo {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  background: #fff;
}

.LineupSlabV3 .text {
  flex-basis: 60%;
}

.LineupSlabV3 .slabHeader {
  height: 12.5%;
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
}

.LineupSlabV3 .title {
  font-size: 1.6vw;
  font-weight: 600;
  display: grid;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.LineupSlabV3 .headerLogo img {
  max-height: 90%;
  margin: auto;
  object-fit: contain;
  max-width: 90%;
  /*  filter: drop-shadow(0 0 0.6rem #fff);*/
}

.LineupSlabV3 .lineup {
  margin-left: 0;
  margin-right: 0;
  height: 87.5%;
}

.LineupSlabV3 .competitorList {
  width: 100%;
  overflow: hidden;
  height: 84%;
}

.LineupSlabV3 .competitorList li {
  height: 1vw;
  min-height: 1.2vw;
  margin-bottom: 1.4vw;
}

.LineupSlabV3 .l6 .competitorList li {
  margin-bottom: 1.4vw;
}

.LineupSlabV3 .l7 .competitorList li {
  margin-bottom: 1.1vw;
}

.LineupSlabV3 .l8 .competitorList li {
  margin-bottom: 0.8vw;
}

.LineupSlabV3 .l9 .competitorList li {
  margin-bottom: 0.8vw;
}

.LineupSlabV3 .competitorList li {
  height: 1vw;
  min-height: 1.2vw;
  margin-bottom: 1.4vw;
}

.LineupSlabV3 .competitorLine {
  min-height: 1vw;
  height: 1vw;
}

.LineupSlabV3 .competitorLine label {
  margin-bottom: 0;
  font-size: 1.4vh;
  font-style: italic;
  margin-top: -0.75vh;
  padding: 0vh 0.5vh 0 0;
  text-align: right;
  width: 95%;
  color: #fff;
  display: none;
}

.LineupSlabV3 .logo {
  background: transparent !important;
  max-width: 12%;
  text-align: center;
}

.LineupSlabV3 .logo span {
  font-size: 1vw;
}

.LineupSlabV3 .headshot,
.LineupSlabV3 .icon {
  background: transparent !important;
  max-width: 15%;
}

.LineupSlabV3 .athlete {
  background: transparent !important;
  min-width: 62%;
}

.LineupSlabV3 .athlete input {
  padding: 0;
  color: #ffffff;
}

.LineupSlabV3 .athlete input:disabled {
  -webkit-text-fill-color: #fff !important;
  color: #ffffff;
}

.LineupSlabV3 .score {
  background: transparent !important;
}

.LineupSlabV3 .score button {
  -webkit-text-fill-color: #fff !important;
  color: #fff;
  font-size: 1.2vw;
}

.LineupSlabV3 .stickIndicator {
  bottom: 0;
  right: -0.2vw;
}

.LineupSlabV3 .penaltyIndicator {
  top: 0;
  right: -0.2vw;
}

.LineupSlabV3 .dScoreIndicator {
  left: -0.75vw;
}

.LineupSlabV3 .scorecard {
  font-size: 1.2vw;
}

.LineupSlabV3 .roundSummary {
  background: transparent !important;
  color: #fff;
  font-size: 1.2vw;
  width: 100%;
  border-top: 2px solid #ccc;
  border-radius: 0 !important;
  height: 2vw;
}

.LineupSlabV3 .team {
  font-size: 1.2vw;
}

.LineupSlabV3 .competitorList span {
  color: #fff;
}

/**************/

.LineupSlab {
  position: absolute;
  right: 5.3%;
  top: 28.2%;
  width: 25.2%;
  height: 53.9%;
  color: #fff;
  background-color: #333;
}

.LineupSlab .slabHeader {
  height: 12.5%;
  margin-left: 0;
  margin-right: 0;
}

.Individual {
  background: #000000;
  height: auto !important;
  border: 4px solid #ffffff;
}

.LineupSlab .slabHeaderIndividual {
  /*  height: 20%;*/
  margin-left: 0;
  margin-right: 0;
  background: linear-gradient(#ffffff, #929292);
  color: #000000;
  border-bottom: 4px solid #ffffff;
}

.LineupSlab .slabHeaderIndividual .title {
  margin: 0.5vw 0;
  text-transform: uppercase;
  font-size: 2vw;
  font-weight: 700;
}

.LineupSlab .slabHeaderIndividual .headerLogo {
  height: auto;
}

.LineupSlab .slabHeaderIndividual .headerLogo svg {
  max-height: 75%;
  margin: auto;
  object-fit: contain;
  max-width: 90%;
}

.LineupSlab .lineupIndividual {
  margin-left: 0;
  margin-right: 0;
  margin: 1vw 0.25vw;
  height: 100%;
}

.LineupSlab .text {
  flex-basis: 60%;
}

.LineupSlab .title {
  font-size: 2.66vw;
  line-height: 2.66vw;
  font-weight: 600;
  margin: 0.5vw 0 0 0;
  min-height: 2.66vw;
}

.LineupSlab .headerLogo img {
  max-height: 100%;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  filter: drop-shadow(0 0 0.6rem #fff);
}

.LineupSlab .lineup {
  margin-left: 0;
  margin-right: 0;
  margin: 0.2vw 0 0 0;
  height: 100%;
}

.LineupSlab .competitorList {
  width: 100%;
  max-height: 60%;
}

.LineupSlab .competitorList li {
  height: 4vh;
  /*min-height: 2vh;*/
}

.LineupSlab .competitorLine {
  min-height: 2vh;
}

.LineupSlab .competitorLine label {
  margin-bottom: 0;
  font-size: 1.4vh;
  font-style: italic;
  margin-top: -0.75vh;
  padding: 0vh 0.5vh 0 0;
  text-align: right;
  width: 95%;
  color: #fff;
}

.LineupSlab .logo {
  background: transparent !important;
  max-width: 12%;
}

.LineupSlab .headshot,
.LineupSlab .icon {
  background: transparent !important;
  max-width: 15%;
}

.LineupSlab .athlete {
  background: transparent !important;
  min-width: 48%;
}

.LineupSlab .athlete input {
  padding: 0;
  color: #ffffff;
}

.LineupSlab .athlete input:disabled {
  -webkit-text-fill-color: #fff;
  color: #ffffff;
}

.LineupSlab .score {
  background: transparent !important;
}

.LineupSlab .score button {
  color: #fff;
  font-size: 1.2vw;
}

.LineupSlab .scorecard {
  font-size: 1.2vw;
}

.LineupSlab .roundSummary {
  background: transparent !important;
  color: #fff;
  font-size: 1.2vw;
  width: 100%;
  border-top: 2px solid #ccc;
  border-radius: 0 !important;
}

.LineupSlab .team {
  font-size: 1.2vw;
}

.LineupSlab .competitorList span {
  color: #fff;
}

.teamroster .form-check-input {
  width: 0.9rem !important;
  height: 40px !important;
  margin: 0 !important;
  padding-left: 0 !important;
}

.teamroster .form-check {
  height: 40px !important;
}

.sticky-bottom-container {
  position: sticky;
  /* bottom: -1rem; */
  left: 0;
  right: 0;
  width: calc(100% + 2rem);
  background: white;
  padding: 1rem 1rem 0;
  box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
}
.photo-matches-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.photo-matches-container {
  background: white;
  border-radius: 4px;
  padding: 2rem;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
}

.photo-matches-container .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.photo-matches-container .buttons-container {
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.photo-matches-container .match-option {
  padding: 0.125rem 0.25rem;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.125rem;
  white-space: nowrap;
}

.photo-matches-container .match-option.selected {
  background: #e9ecef;
  border-color: #ced4da;
}

.photo-matches-container .match-option:hover {
  background: #f8f9fa;
}

.photo-matches-container .match-option .match-content {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 1rem;
}

.photo-matches-container .match-score {
  font-size: 0.75rem;
  color: #6c757d;
  padding-left: 1.5rem;
}

.photo-matches-container .skip-checkbox {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #6c757d;
  white-space: nowrap;
}

.photo-matches-container .added-indicator {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #198754;
}

.photo-matches-container .skipped {
  opacity: 0.5;
}

.photo-matches-container .skipped .skip-checkbox {
  opacity: 1;
}

.photo-matches-container .athlete-name {
  min-width: 200px;
  font-weight: 500;
}

.photo-matches-container .athlete-number {
  min-width: 2rem;
  text-align: right;
  color: #6c757d;
  font-weight: 500;
  padding-right: 0.5rem;
}

.photo-matches-container .matches-container {
  flex-grow: 1;
  overflow-x: auto;
  padding: 0.5rem 0;
  display: flex;
  gap: 0.5rem;
}

.photo-matches-container input[type='radio'] {
  margin: 0;
  width: 0.75rem;
  height: 0.75rem;
}

.photo-matches-container .gap-2 {
  gap: 0.5rem !important;
}

.photo-matches-container .btn-outline-secondary {
  font-size: 0.75rem;
  padding: 0.125rem 0.5rem;
}
